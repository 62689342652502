import { OneAppOrderItem, OneAppOrder } from '@oneAppCore/types/orderTypes';

const productLink = (supplierList: any[], orderItem: OneAppOrderItem) => {
  let returnable = '';
  if (!orderItem?.listingSku) return returnable;
  const { listingSku, supplierId } = orderItem;
  // const supplierList = await SupplierApi.getSuppliersMetaData();
  // console.log('list', supplierList.filter(metaItem => metaItem.id === supplierId));
  if (supplierList.filter((metaItem) => metaItem.id === supplierId)[0]) {
    const {
      metaData: { url },
    } = supplierList.filter((metaItem) => metaItem.id === supplierId)[0];
    if (!url.product) return returnable;
    console.log('url.product', url.product);
    const arr = url.product;
    switch (supplierId) {
      case 1:
        returnable = arr[0].replace(arr[1], listingSku.split('*')[0]);
        console.log('returnable', returnable);
        break;
      case 7:
        returnable = '';
        break;
    }
    return returnable;
  }
};
const trackingLink = (
  supplierList: any[],
  orderItem: OneAppOrderItem,
  order: OneAppOrder,
) => {
  let returnable = '';
  if (!orderItem?.supplierOrderNo) return returnable;
  const { supplierId, supplierOrderNo } = orderItem;
  if (supplierList.filter((metaItem) => metaItem.id === supplierId)[0]) {
    const {
      metaData: { url },
    } = supplierList.filter((metaItem) => metaItem.id === supplierId)[0];
    if (!url?.tracking) return returnable;
    const arr = url.tracking;
    switch (supplierId) {
      case 1:
        returnable = arr[0]
          .replace(arr[1], supplierOrderNo)
          .replace(arr[2], order.zipCode);
        break;
      case 2:
        returnable = arr[0].replace(arr[1], supplierOrderNo);
        break;
      case 3:
        returnable = arr[0].replace(arr[1], supplierOrderNo);
        break;
      case 5:
        returnable = arr[0].replace(arr[1], supplierOrderNo);
        break;
      case 7:
        returnable = '';
        break;
    }
    return returnable;
  }
};

export default {
  productLink,
  trackingLink,
};
