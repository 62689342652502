import { useMemo, useState } from 'react';
import useVariations from '@src/hooks/swr/useVariations';

import AppSelect from '../AppSelect';

import type { Props } from './types';

function VariationSelect(props: Props) {
  const { data: variations } = useVariations({
    mpn: props.mpn,
    brandId: props.brandId,
  });
  const { data: allVars } = useVariations({});
  const [hdl, sethdl] = useState(false);

  const options = useMemo(() => {
    if (!variations?.rows) {
      if (!allVars?.rows) {
        return [];
      } else {
        return allVars?.rows.map((v) => {
          let variant = `, ${v.variation}`;
          if (!v.variation) variant = '';
          return {
            value: v.id,
            label: `${v.brand}, ${v.sku}${variant}`,
          };
        });
      }
    } else {
      if (variations?.rows?.some((v) => v.id === props.value)) {
        return variations.rows
          .filter((x) => x.id === props.value)
          .map((e) => {
            let variant = `, ${e.variation}`;
            if (!e.variation) variant = '';
            return {
              value: e.id,
              label: `${e.brand}, ${e.sku}${variant}`,
            };
          });
      } else {
        return variations?.rows.map((v) => {
          let variant = `, ${v.variation}`;
          if (!v.variation) variant = '';
          return {
            value: v.id,
            label: `${v.brand}, ${v.sku}${variant}`,
          };
        });
      }
    }
  }, [variations, allVars]);
  return (
    <AppSelect
      options={options}
      label={props?.label || 'Product, SKU, Variation'}
      {...props}
      style={{
        backgroundColor: props.backgroundColor || 'transparent',
      }}
      value={
        typeof props?.value === 'object'
          ? props.value
          : options?.length > 0 ? options[0] : {}
      }
    />
  );
}

export default VariationSelect;
