import { useHistory } from 'react-router-dom';
import { Tooltip, Grid, Button } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { THEME_PRIMARY } from '@oneAppCore/constants/colors';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';

const useStyles = makeStyles((theme: Theme) => ({
  button: {
    color: THEME_PRIMARY.contrastText,
    backgroundColor: THEME_PRIMARY.main,
  },
}));

function CreateButton() {
  const classes = useStyles();
  const history = useHistory();

  return (
    <>
      <Grid item style={{ justifyContent: 'flex-end', float: 'right' }}>
        <Tooltip title={'Create New Product'}>
          <Grid alignItems="center" style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor:'pointer'
          }}>
            <AddCircleRoundedIcon
              color="primary"
              onClick={() => history.push('/products/create')}
              sx={{
                fontSize: 'xx-large',
              }}
            />
          </Grid>
        </Tooltip>
      </Grid>
    </>
  );
}

export default CreateButton;
