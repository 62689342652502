import { Grid, TableCell, TableRow } from '@mui/material';
import Typography from '@mui/material/Typography';
import { ReactElement } from 'react';
import dayjs from 'dayjs';
import { getColor } from '@src/utils/colors';
import {
  convertDollarsToCents,
  formatCentsToDollars,
} from '@oneAppCore/utils/currency';

export const issueColumns = ['issueUsername', 'issueType'];

export const columns = [
  {
    id: 3,
    title: 'Order Total:',
    key: 'price',
    align: 'left',
    customComponent: function SalePrice({
      order,
      itemIndex,
      supplierRadioSelection,
      totals,
    }): ReactElement {
      const { orderItems, price, channelId } = order;
      const centsPrice = convertDollarsToCents(price);
      const totalObj = totals?.find(
        (t) => t?.supplier === supplierRadioSelection,
      );
      const totalCost =
        order.supplierShippingCost &&
        orderItems.every((item) => item.supplierSubTotal)
          ? orderItems.reduce((acc, curr) => acc + curr.supplierSubTotal, 0)
          : orderItems.length === 1 && totalObj
          ? totalObj.total
          : 0;

      let totalShipPrice = 0;
      let totalPrice = order.orderItems?.reduce((acc, item) => {
        totalShipPrice += item?.shippingPrice || 0;
        return acc + item.itemPrice;
      }, 0);
      const feeFactor =
        channelId === 1
          ? 0.12
          : channelId === 2
          ? 0.11
          : channelId === 3
          ? 0.13
          : 0.12;
      const totalFees = order.channelFee
        ? order.channelFee
        : order?.orderItems?.every((e) => e.itemFee)
        ? order.orderItems.reduce((acc, item) => acc + item.itemFee, 0)
        : Math.floor(totalPrice * feeFactor);
      totalPrice += totalShipPrice;
      const profit =
        totalCost +
        (order?.supplierShippingCost || totalObj?.supplierShippingCost || 0)
          ? centsPrice -
            totalFees -
            (totalCost + order?.supplierShippingCost ||
              totalObj?.supplierShippingCost ||
              0)
          : 0;
      const profitTextColor =
        profit && profit > 0
          ? getColor('green', 'main')
          : getColor('red', 'main');

      if (totalShipPrice === 0) {
        totalShipPrice =
          order?.supplierShippingCost || totalObj?.supplierShippingCost || 0;
      }
      return (
        <Grid container xs={12}>
          <Grid container spacing={2.5} sx={{ display: 'flex' }}>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                style={{
                  textAlign: 'left',
                  fontSize: '1rem',
                  fontWeight: 'bold',
                }}
              >
                Order Information
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 'bold',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                ${price.toFixed(2)}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{
                  color: 'grey',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                Total
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 'bold',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                {formatCentsToDollars(totalCost)}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{
                  color: 'grey',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                Items
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 'bold',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                {formatCentsToDollars(totalShipPrice)}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{
                  color: 'grey',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                Shipping
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 'bold',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                {formatCentsToDollars(totalFees)}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{
                  color: 'grey',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                Fee
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 'bold',
                  color: profitTextColor,
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >{`${formatCentsToDollars(profit)}`}</Typography>
              <Typography
                variant="subtitle2"
                style={{
                  color: 'grey',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                Profit
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 'bold',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                {order.orderNumber}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{
                  color: 'grey',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                Order Number
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 'bold',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                {order.orderItems[0]?.listingSku}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{
                  color: 'grey',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                Part Number
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 'bold',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                {order.orderItems[0]?.supplierOrderNo
                  ? order.orderItems[0]?.supplierOrderNo
                  : 'N/A'}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{
                  color: 'grey',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                Supplier Order Number
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 'bold',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                {order.channel}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{
                  color: 'grey',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                Channel
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 'bold',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                {order.orderStatus}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{
                  color: 'grey',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                Status
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    },
  },
  {
    id: 7,
    title: 'Purchase Date:',
    key: 'purchaseDate',
    align: 'left',
    customComponent: function purchaseDate({ order, itemIndex }): ReactElement {
      console.log(order, 'order');
      return (
        <Grid container xs={12} justifyContent="flex-start">
          <Grid container spacing={2.5}>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                style={{
                  paddingTop: 20,
                  textAlign: 'left',
                  fontWeight: 'bold',
                  fontSize: '1rem',
                }}
              >
                Shipping Information
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 'bold',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                {dayjs(order.purchaseDate)
                  .tz(dayjs.tz.guess())
                  .format('MM/DD/YYYY hh:mma z')}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{
                  color: 'grey',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                Purchase Date
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 'bold',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                {order.orderItems[0]?.placedBy
                  ? order.orderItems[0]?.placedBy
                  : 'Order Not Placed'}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{
                  color: 'grey',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                Placed By
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 'bold',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                {order.orderItems[0]?.supplierTrackingNo
                  ? order.orderItems[0]?.supplierTrackingNo
                  : 'No Tracking'}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{
                  color: 'grey',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                Tracking #
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 'bold',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                {order.orderItems[0]?.trackedBy
                  ? order.orderItems[0]?.trackedBy
                  : 'No Tracking'}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{
                  color: 'grey',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                Tracked By
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 'bold',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                {order.addressLine1} {order.addressLine2} {order.addressLine3}
                {order.city}, {order.state} {order.zipCode} {order.country}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{
                  color: 'grey',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                Shipping Address
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle1"
                style={{
                  fontWeight: 'bold',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                {order.shippingName}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{
                  color: 'grey',
                  textAlign: 'left',
                  fontSize: '0.85rem',
                }}
              >
                Buyer Name
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    },
  },
  {
    id: 14,
    title: 'Issue Type:',
    key: 'issueType',
    align: 'left',
    customComponent: function issue({ order, itemIndex }): ReactElement {
      return (
        <Grid container xs={12} justifyContent="flex-start">
          <Grid container spacing={2.5}>
            <Grid item xs={12}>
              <Typography
                variant="h5"
                style={{
                  paddingTop: 20,
                  textAlign: 'left',
                  fontWeight: 'bold',
                  fontSize: '1rem',
                }}
              >
                Order Issues
              </Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography
                variant="subtitle1"
                style={{ fontWeight: 'bold', textAlign: 'left' }}
              >
                {order.issueType ? order.issueType : 'No Issues'}
              </Typography>
              <Typography
                variant="subtitle2"
                style={{ color: 'grey', textAlign: 'left' }}
              >
                Issue Type
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      );
    },
  },
];
