import Grid from '@mui/material/Grid';
import { Typography, Chip, Tooltip, Link, TextField } from '@mui/material';

import EditButton from './EditButton';
import ViewButton from './ViewButton';
import ListingButton from './ListingButton';
import OutOfStockButton from './OutOfStockButton';
import StaticQuantityButton from './StaticQuantityButton';
import NoRestockButton from './NoRestockButton';
import { formatCentsToDollarsIntl } from '@oneAppCore/utils/currency';
import { getStartLetters } from '@oneAppCore/utils/formatters';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import { useHistory } from 'react-router-dom';
import type { Column } from '@src/Components/common/containers/SearchGrid/types';
import dayjs from 'dayjs';
import { SUPP_UN } from '@oneAppCore/constants/suppliers';
import { useGridApiContext, GridRenderCellParams } from '@mui/x-data-grid-premium';

export const columns = (internalSuppliers): Column[] => [
  {
    keyName: 'image',
    title: 'Image',
    align: 'center',
    width: 75,
    minWidth: 15,
    customComponent: function InventoryImage(row) {
      return (
        <Grid container justifyContent="center" alignItems="center">
          <Grid item>
            <img
              src={
                row.images?.[0]?.imageUrl != null
                  ? row.images?.[0]?.imageUrl
                  : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`;
              }}
              alt={`product image for ${row.manufacturerNo}`}
              width={45}
              height={45}
              style={{ borderRadius: 5 }}
            />
          </Grid>
        </Grid>
      );
    },
  },

  {
    keyName: 'supplierName',
    title: 'Supplier',
    sortBy: true,
    align: 'center',
    width: 100,
    minWidth: 15,
    customComponent: function Suppliers(row) {
      return (
        <Grid item container style={{ justifyContent: 'center' }}>
          {row?.metaData?.shortName ||
            getStartLetters(row?.supplierName, { caps: true }) ||
            SUPP_UN}
        </Grid>
      );
    },
  },
  {
    keyName: 'brand',
    title: 'Brand',
    sortBy: true,
    align: 'center',
    width: 150,
    minWidth: 15,
  },
  {
    keyName: 'manufacturerNo',
    title: 'MPN',
    sortBy: true,
    align: 'center',
    width: 150,
    minWidth: 15,
  },
  {
    keyName: 'sku',
    title: 'SKU',
    sortBy: true,
    align: 'center',
    width: 125,
    minWidth: 15,
    customComponent: function sku(row) {
      const history = useHistory();

      return (
        <Grid xs={12}>
          <Tooltip title={'View product'}>
            <Link href={`/products/view/${row.productId}`}>{row.sku}</Link>
          </Tooltip>
        </Grid>
      );
    },
  },
  {
    keyName: 'quantity',
    title: 'QTY(Cur / Inb)',
    sortBy: true,
    align: 'center',
    editable: true,
    width: 125,
    minWidth: 15,
    customEdit: function (params: GridRenderCellParams) {
      const { row, id, field } = params;
      const apiRef = useGridApiContext();
      const isInternal = internalSuppliers.includes(row?.supplierId || 0);
      const fielda = isInternal ? 'oldQuantity' : 'quantity';

      const handleChange = (event) => {
        const value = event.currentTarget.value;
        apiRef.current.setEditCellValue({ id, field, value });
      };

      return <>
        <Grid xs={12}>
          <TextField
            variant='standard'
            defaultValue={row[fielda]}
            type='number'
            onChange={handleChange}
          />
        </Grid>
      </>;
    },
    customComponent: function quantity(row) {
      const isInternal = internalSuppliers.includes(row?.supplierId || 0);
      return (
        <>
          <Grid xs={12}>
            {row?.inbound === null ? (
              <Typography>{isInternal ? row?.oldQuantity : row?.quantity} / 0</Typography>
            ) : (
              <Typography>
                {isInternal ? row?.oldQuantity : row?.quantity} /{' '}
                <span style={{ color: 'darkgreen', fontWeight: 'bold' }}>
                  {' '}
                  {row?.inbound}{' '}
                </span>
              </Typography>
            )}
          </Grid>
        </>
      );
    },
  },
  {
    keyName: 'cost',
    title: 'Cost/(MSRP)',
    sortBy: true,
    align: 'center',
    width: 125,
    minWidth: 15,
    customComponent: function cost(row) {
      const msrp = row.msrp && row.msrp > 0 ? row.msrp : null;
      const reduceFunc = (max, curr) => {
        if (curr?.discountAmount > max?.discountAmount) {
          return curr;
        } else {
          return max;
        }
      };
      let discounts = [];
      if (row?.discountVariations?.filter((i: any) => i)?.length) {
        discounts = row?.discountVariations?.filter((i: any) => i);
      } else if (row?.discountProducts?.filter((i: any) => i)?.length) {
        discounts = row?.discountProducts?.filter((i: any) => i);
      } else if (row?.discountBrands?.filter((i: any) => i)?.length) {
        discounts = row?.discountBrands?.filter((i: any) => i);
      }
      const discountFunc = (disc) => {
        if (disc.discountType === 'f') {
          return row.cost - disc.discountAmount;
        } else if (disc.discountType === 'p') {
          return Math.ceil(row.cost * ((100 - disc.discountAmount) / 100));
        } else if (disc.discountType === 'd') {
          return disc.discountAmount;
        } else {
          return 0;
        }
      };
      const discountAmount = discounts.length ? discountFunc(discounts[0]) : 0;
      const discount = msrp
        ? Math.ceil(100 - ((discountAmount || row.cost) / msrp) * 100)
        : 0;
      const rend = (
        <>
          <Grid item>
            <Typography>{formatCentsToDollarsIntl(row.cost)}</Typography>
          </Grid>
          {msrp ? (
            <Grid item>
              <Typography>{` \n/(${formatCentsToDollarsIntl(
                msrp,
              )})`}</Typography>
            </Grid>
          ) : null}
          {discount ? (
            <Grid item>
              <Typography>
                <span style={{ color: 'darkgreen' }}>{discount}</span>
                <span style={{ color: 'darkgreen' }}>% off</span>
              </Typography>
            </Grid>
          ) : null}
        </>
      );

      return (
        <Grid container style={{ justifyContent: 'center' }}>
          {rend}
        </Grid>
      );
    },
  },
  {
    keyName: 'map',
    title: 'MAP',
    sortBy: false,
    align: 'center',
    width: 125,
    minWidth: 15,
    customComponent: function cost(row) {
      const map = row.map && row.map > 0 ? row.map : null;
      return (
        <Grid container style={{ justifyContent: 'center' }}>
          {map ? (
            <Grid item>
              <Typography>{`${formatCentsToDollarsIntl(map)}`}</Typography>
            </Grid>
          ) : null}
        </Grid>
      );
    },
  },
  {
    keyName: 'location',
    title: 'Location',
    sortBy: true,
    align: 'center',
    editable: true,
    width: 125,
    minWidth: 75,
    customComponent: function Location(row) {
      // tooltip to show full location on hover?
      // ? maybe add a switch for the background color?
      return (
        <Grid item container xs={12} style={{ justifyContent: 'center' }}>
          {row?.location !== null ? (
            <Chip
              style={{
                color: '#000000',
                backgroundColor: row?.location?.includes('B BIN', 'B BIn')
                  ? '#C7E2FF'
                  : row?.location?.includes('BIN')
                    ? '#FFB9B9'
                    : row?.location?.includes('Pegboard')
                      ? '#FFBFA4'
                      : row?.location?.includes('Peg Board')
                        ? '#FFBFA4'
                        : row?.location?.includes('ENDCAP')
                          ? '#AFFFA4'
                          : row?.location?.includes('Endcap')
                            ? '#AFFFA4'
                            : row?.location?.includes('Wall')
                              ? '#A4FFE1'
                              : row?.location?.includes('Clip')
                                ? '#B7A4FF'
                                : row?.location?.includes('Sorter')
                                  ? '#FFD972'
                                  : row?.location?.includes('MAN')
                                    ? '#A7E8BD'
                                    : row?.location?.includes('Red')
                                      ? '#BE3E82'
                                      : row?.location?.includes('B Endcap')
                                        ? '#D8D4F2'
                                        : row?.location?.includes('Backroom')
                                          ? '#BC9CB0'
                                          : row?.location?.includes('Glass')
                                            ? '#81D2C7'
                                            : row?.location?.includes('Battery')
                                              ? '#C1F7DC'
                                              : row?.location?.includes('Tire') && '#FFA4DB',
                minWidth: '90%',
              }}
              label={row.location?.trim() !== '' ? row.location : 'No Location'}
            />
          ) : (
            <></>
          )}
        </Grid>
      );
    },
  },
  {
    keyName: 'outOfStock',
    title: 'Temp OOS',
    sortBy: false,
    align: 'center',
    width: 100,
    minWidth: 15,
    customComponent: function customSwitch(row) {
      return (
        <OutOfStockButton outOfStock={row.tempOOS} id={row.id} key={row.id} />
      );
    },
  },
  {
    keyName: 'noRestock',
    title: 'No Restock',
    sortBy: false,
    align: 'center',
    width: 100,
    minWidth: 15,
    customComponent: function customSwitch(row) {
      return (
        <NoRestockButton noRestock={row.noRestock} id={row.id} key={row.id} />
      );
    },
  },
  {
    keyName: 'updatedAt',
    type: 'date',
    sortBy: true,
    align: 'center',
    title: 'Updated At',
    width: 200,
    minWidth: 15,
    customComponent: function updatedAt(row) {
      return (
        <Grid>
          <Typography>
            {dayjs(row.updatedAt)
              .tz(dayjs.tz.guess())
              .format('MM/DD/YYYY hh:mma z')}
          </Typography>
        </Grid>
      );
    },
  },
  {
    keyName: 'staticQuantity',
    title: 'Static QTY',
    sortBy: false,
    align: 'center',
    width: 100,
    minWidth: 15,
    customComponent: function customSwitch(row) {
      return (
        <StaticQuantityButton
          staticQuantity={row.staticQ}
          id={row.id}
          key={row.id}
        />
      );
    },
  },
  {
    keyName: 'daysSinceLastSold',
    title: 'Days Aged',
    sortBy: false,
    align: 'center',
    width: 100,
    minWidth: 15,
  },
  {
    keyName: 'actions',
    title: 'Actions',
    sortBy: false,
    align: 'center',
    width: 100,
    minWidth: 15,
    customComponent: function CustomEdit(row) {
      const isInternal = internalSuppliers.includes(row?.supplierId || 0);
      // return <EditButton key={row.id} row={row} />;
      return (
        <Grid container xs={12}>
          <Grid item xs={4}>
            <ListingButton key={row.id} row={row} />
          </Grid>
          <Grid item xs={4}>
            <EditButton key={row.id} row={row} isInternal={isInternal} />
          </Grid>
          <Grid item xs={4}>
            <ViewButton id={row.id} row={row} />
          </Grid>
        </Grid>
      );
    },
  },
];

export const stockProperties = [
  { label: 'Temp Out of Stock', value: 'quantityTrackingUnavailable' },
  { label: 'Static Quantity', value: 'hasStaticQuantity' },
  { label: 'Restock Ignored', value: 'noRestock' },
  { label: 'Out of Stock', value: 'outOfStock' },
  { label: 'In Stock', value: 'inStock' },
];
