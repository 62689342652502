
export const INVENTORY = 'inventory';
export const SUPP_COUNT = 'supplier counts';
export const SUP_STOCK = 'supplier-stock';
export const SUP_COUNT = 'supplier-count';
export const SUP_OOS = 'supplier-oos';
export const BRD_STOCK = 'brand-stock';
export const BRD_OOS = 'brand-oos';
export const SUP_IR_STOCK = 'supplier-ir-stock';
export const SUP_IR_OOS = 'supplier-ir-oos';
export const BRD_IR_STOCK = 'brand-ir-stock';
export const BRD_IR_OOS = 'brand-ir-oos';
