import { TableCell, TableHead, TableRow } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import DescIcon from '@src/Components/common/containers/SearchView/SearchTable/SearchHeader/DirectionIcons/DescIcon';
import AscIcon from '@src/Components/common/containers/SearchView/SearchTable/SearchHeader/DirectionIcons/AscIcon';
import { Column } from '@src/Components/common/containers/SearchView/types';

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    minWidth: 650,
  },
  cellContentWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  filter: {
    width: 20,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(0.5),
  },
  column: {
    padding: theme.spacing(1),
    border: 0,
  },

  headerColor: {
    backgroundColor: '#f3f2f7',
    border: 0,
    whiteSpace: 'nowrap',
  },
}));

type Props = {
  variation?: any;
};

export default function TableHeader({ variation }: Props) {
  const classes = useStyles();

  return (
    <TableHead className={classes.headerColor}>
      <TableRow>
        {variation > 1 && (
          <TableCell align="center" className={classes.column}>
            Variation
          </TableCell>
        )}
        <TableCell align="center" className={classes.column}>
          SKU
        </TableCell>
        <TableCell align="center" className={classes.column}>
          Manufacturer No.
        </TableCell>
        <TableCell align="center" className={classes.column}>
          UPC
        </TableCell>
        <TableCell align="center" className={classes.column}>
          Qty/Box
        </TableCell>
        <TableCell align="center" className={classes.column}>
          MAP
        </TableCell>
        <TableCell align="center" className={classes.column}>
          MSRP
        </TableCell>
        <TableCell align="center" className={classes.column}>
          Price
        </TableCell>
        <TableCell align="center" className={classes.column}>
          Delete
        </TableCell>
      </TableRow>
    </TableHead>
  );
}
