import type { Column } from '@src/Components/common/containers/SearchView/types';
import { formatCentsToDollars } from '@oneAppCore/utils/currency';
import {
  IconButton,
  Modal,
  Typography,
  Divider,
  Grid,
  Link,
  Tooltip,
} from '@mui/material';
import { FontAwesome } from '@src/Components/common';
import VariationsApi from '@oneAppCore/services/VariationsApi';
import FileUpload from '@src/Components/common/buttons/FileUpload';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import EditTwoToneIcon from '@mui/icons-material/EditTwoTone';
import { useSnackbar } from 'notistack';

export const columns: Column[] = [
  {
    title: 'Image',
    keyName: 'image',
    sortBy: false,
    customComponent: function ImageRender({
      row,
      setImageCell,
      imageCell,
      cellIndex,
      mutate,
    }) {
      const showModal = () => {
        cellIndex === imageCell ? setImageCell() : setImageCell(cellIndex);
      };

      const updateImageUrl = async (url) => {
        const newImage = url[0];
        try {
          await VariationsApi.updateImage(
            { url: newImage },
            row.imageId,
          ).then(() => mutate());
        } catch (e) {
        } finally {
          setImageCell(null);
        }
      };

      return (
        <div>
          {imageCell === cellIndex ? (
            <div
              key={cellIndex}
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Modal
                open={imageCell === cellIndex}
                onClose={() => setImageCell()}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <div
                  style={{
                    width: 500,
                    backgroundColor: 'white',
                    padding: 20,
                    borderRadius: 10,
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      marginBottom: 10,
                    }}
                  >
                    <img
                      style={{ cursor: 'pointer' }}
                      src={row.imageUrl?.[0]}
                      alt=""
                      width={400}
                      height={400}
                      onClick={showModal}
                    />
                  </div>
                  <Grid item xs={12} style={{ marginBottom: 15 }}>
                    <Divider />
                  </Grid>

                  <FileUpload
                    id="notSureWhyThisRequiresAnIdThatCantBeANumberButIGuessThatsFine"
                    onUpload={(url) => updateImageUrl(url)}
                    buttonText="Upload New Photo"
                  />
                </div>
              </Modal>
            </div>
          ) : (
            <img
              style={{ cursor: 'pointer' }}
              src={
                row.imageUrl?.length
                  ? row.imageUrl?.[0]
                  : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
              }
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`;
              }}
              alt=""
              width={50}
              height={50}
              onClick={showModal}
            />
          )}
        </div>
      );
    },
    editable: false,
  },
  {
    title: 'Variant',
    keyName: 'title',
    sortBy: true,
    customComponent: function Title({ row }) {
      let retRow = row.name;
      if (row.variation) {
        retRow = row.variation;
      }
      return <>{retRow}</>;
    },
    customTitle: function Custom({ row }) {
      if (Array.isArray(row) && row.length > 0 && row[0].variantType && row[0].variantType !== '' && row[0].variantType !== 'NONE') {
        return (
          <>
            {`${row[0].variantType}`}
          </>
        );
      }
      else {
        return <>{'Title'}</>;
      } 
    },
    editable: true,
  },
  {
    title: 'SKU',
    keyName: 'sku',
    sortBy: false,
    editable: true,
  },
  {
    title: 'MPN',
    keyName: 'manufacturerNo',
    sortBy: false,
    editable: true,
    customComponent: function Mpn({ row }) {
      const handleClick = () => {
        localStorage.setItem('selectedVarMpn', row?.manufacturerNo);
      };

      return (
        <>
          <Tooltip title={'View Inventory Items'}>
            <Link href={`/inventory/list`} onClick={handleClick}>
              {row.manufacturerNo}
            </Link>
          </Tooltip>
        </>
      );
    },
  },
  {
    title: 'UPC',
    keyName: 'upc',
    sortBy: false,
    editable: true,
  },
  {
    title: 'Qty/Box',
    keyName: 'quantityPerBox',
    sortBy: false,
    editable: true,
  },
  {
    title: 'MAP',
    keyName: 'map',
    sortBy: false,
    editable: true,
    customComponent: function Map({ row }) {
      return (
        <>
          <Grid>
            {row.map ? (
              <Typography>${(row.map / 100).toFixed(2)}</Typography>
            ) : null}
          </Grid>
        </>
      );
    },
  },
  {
    title: 'MSRP',
    keyName: 'msrp',
    sortBy: false,
    editable: true,
    customComponent: function MSRP({ row }) {
      return (
        <>
          <Grid>
            {row.msrp ? (
              <Typography>
                {/* ${formatCentsToDollars(row.msrp, { removeMoneySign: true })} */}
                ${(row.msrp / 100).toFixed(2)}
              </Typography>
            ) : null}
          </Grid>
        </>
      );
    },
  },

  {
    title: 'Price',
    keyName: 'itemPrice',
    sortBy: false,
    editable: true,
    customComponent: function Price({ row }) {
      const array = row?.suppliers;
      const noDisc = Math.min.apply(
        Math,
        array?.map((item) => item?.cost + item?.shippingCost),
      );

      const withFlatDisc = Math.min.apply(
        Math,
        array?.map(
          (item) =>
            item?.cost / 100 - item?.discount + item?.shippingCost / 100,
        ),
      );

      const withPercDisc = Math.min.apply(
        Math,
        array?.map(
          (item) =>
            item?.cost / 100 -
            (item?.cost / 100) * (item?.discount / 100) +
            item?.shippingCost / 100,
        ),
      );

      const discount = array?.map((item) => item.discount);
      const flat = array?.map((item) => item.flat);
      const percent = array?.map((item) => item.percent);
      return (
        <>
          <Grid>
            {row.itemPrice ? (
              <Typography>${(row.itemPrice / 100).toFixed(2)}</Typography>
            ) : (
              <Typography>$0.00</Typography>
            )}
            {/* {row.} */}
          </Grid>
          {/* <Grid>
            {discount !== null ? (
              flat !== null ? (
                <Typography>
                  {((noDisc - flat) / row.price) * 100}% Profit
                </Typography>
              ) : (
                percent
              )
            ) : (
              <Typography style={{ fontSize: 'small' }}>
                {((noDisc / row.price) * 100).toFixed(0)}% Profit
              </Typography>
            )}
          </Grid> */}
        </>
      );
    },
    // customComponent: function Price({ row }) {
    //   const array = row?.suppliers;
    //   const noDisc = Math.min.apply(
    //     Math,
    //     array?.map((item) => item?.cost + item?.shippingCost),
    //   );

    //   const withFlatDisc = Math.min.apply(
    //     Math,
    //     array?.map(
    //       (item) =>
    //         item?.cost / 100 - item?.discount + item?.shippingCost / 100,
    //     ),
    //   );

    //   const withPercDisc = Math.min.apply(
    //     Math,
    //     array?.map(
    //       (item) =>
    //         item?.cost / 100 -
    //         (item?.cost / 100) * (item?.discount / 100) +
    //         item?.shippingCost / 100,
    //     ),
    //   );
    //   const discount = array?.map((item) => item.discount);
    //   const flat = array?.map((item) => item.flat);
    //   const suggFlat = withFlatDisc * suggestProfitMultiplier(15);
    //   const suggPerc = withPercDisc * suggestProfitMultiplier(15);
    //   const suggPrice = noDisc * suggestProfitMultiplier(15);
    //   const formatted = formatCentsToDollars(Math.ceil(suggPrice));
    //   if (row.suppliers !== null) {
    //     if (discount !== null) {
    //       if (flat === true) {
    //         return <>${suggFlat.toFixed(2)}</>;
    //       } else {
    //         return <>${suggPerc.toFixed(2)}</>;
    //       }
    //     } else {
    //       return <>${formatted}</>;
    //     }
    //   } else {
    //     return (
    //       <Typography style={{ color: '#c91625' }}>
    //         Please fill out supplier info
    //       </Typography>
    //     );
    //   }
    // },
  },
  {
    title: 'Stock',
    keyName: 'quantity',
    sortBy: true,
    customComponent: function StockRow({ row }) {
      if (row.suppliers) {
        return (
          <>
            {row.suppliers
              .map((x) => x.quantity)
              .reduce((partial, a) => partial + a, 0)}
          </>
        );
      }
    },
    editable: false,
  },
  // {
  //   title: 'Item Cost',
  //   keyName: 'total',

  //   sortBy: true,
  //   customComponent: function TotalCost({ row }) {
  //     const array = row.suppliers;
  //     const minCost = Math.min(
  //       ...array.map((item) => item.cost + item.shippingCost),
  //     );
  //     if (row.suppliers) {
  //       return (
  //         <>
  //           {/* ${(minCost / 100).toFixed(2)} */}
  //           {(
  //             (row.suppliers
  //               .map((x) => x.cost)
  //               .reduce((partial, a) => partial + a, 0) +
  //               row.suppliers
  //                 .map((x) => x.shippingCost)
  //                 .reduce((partial, a) => partial + a, 0)) /
  //             100
  //           ).toFixed(2)}
  //         </>
  //       );
  //     }
  //   },
  //   editable: false,
  // },
  // {
  //   title: 'Kit QTY',
  //   keyName: 'kitQTY',
  //   align: 'center',
  //   editable: true,
  //   sortBy: true,
  //   // customComponent: function qtyBox(row) {
  //   //   return <TextField variant="outlined" size="small" />;
  //   // },
  // },
  {
    title: 'Edit',
    keyName: 'edit',
    align: 'center',
    editable: false,
    sortBy: false,
    editHandler: function handleEditing(
      row,
      newRow,
      isEditMode,
      setEditMode,
      index,
      editCell,
      setEditCell,
      mutate,
    ) {
      const { enqueueSnackbar } = useSnackbar();
      // const callApi = async () => {
      //   const {
      //     title: variation,
      //     manufacturerNo,
      //     sku,
      //     itemPrice,
      //     msrp,
      //     map,
      //   } = newRow;
      //   const payload = {
      //     variation,
      //     manufacturerNo,
      //     sku,
      //     itemPrice: itemPrice === row.itemPrice ? row.itemPrice : itemPrice,
      //     msrp: msrp === row.msrp ? row.msrp : msrp,
      //     map: map === row.map ? row.map : map,
      //   };
      //   if (
      //     variation === row.title ||
      //     manufacturerNo === row.manufacturerNo ||
      //     sku === row.sku ||
      //     itemPrice === row.itemPrice ||
      //     msrp === row.msrp ||
      //     map === row.map
      //   ) {
      //     try {
      //       await VariationsApi.put(row.variationId, payload)
      //         .then((res) => {
      //           setEditCell();
      //           setEditMode(false);
      //           mutate();
      //         })
      //         .catch((e) => console.log(e));
      //     } catch (e) {
      //       enqueueSnackbar(e.message, { variant: 'error' });
      //     }
      //   }
      // };

      const callApi = async () => {
        const {
          title: variation,
          manufacturerNo,
          sku,
          itemPrice,
          msrp,
          map,
        } = newRow;

        const payload = {
          variation,
          manufacturerNo,
          sku,
          itemPrice: itemPrice === row.itemPrice ? row.itemPrice : itemPrice,
          msrp:
            msrp === row.msrp || msrp === undefined
              ? row.msrp === undefined
                ? null
                : row.msrp
              : msrp,
          map:
            map === row.map || map === undefined
              ? row.map === undefined
                ? null
                : row.map
              : map,
        };

        if (
          variation !== row.title ||
          manufacturerNo !== row.manufacturerNo ||
          sku !== row.sku ||
          itemPrice !== row.itemPrice ||
          msrp !== row.msrp ||
          map !== row.map
        ) {
          try {
            await VariationsApi.put(row.variationId, payload);
            setEditCell();
            setEditMode(false);
            mutate();
            enqueueSnackbar('Variation Updated!', { variant: 'success' });
          } catch (e) {
            enqueueSnackbar(e.message, { variant: 'error' });
          }
        }
      };

      if (!isEditMode) {
        return (
          <Grid
            item
            container
            xs={12}
            alignItems="center"
            style={{ justifyContent: 'center' }}
          >
            <IconButton
              onClick={() => {
                setEditCell(index);
                setEditMode(true);
              }}
            >
              <EditTwoToneIcon color="primary" fontSize="large" />
            </IconButton>
          </Grid>
        );
      } else if (isEditMode && index === editCell) {
        return (
          <IconButton onClick={() => callApi()}>
            <FontAwesome
              name="check"
              type="fas"
              form="fa"
              size="lg"
              style={{ cursor: 'pointer', color: '#50C878' }}
            />
          </IconButton>
        );
      }
    },
  },
  {
    title: 'Multipack',
    keyName: 'multipack',
    align: 'center',
    sortBy: false,
    customComponent: function multipackDropdown({
      setOpenedCell,
      openedCell,
      openedType,
      cellIndex,
    }) {
      const setRow = () => {
        if (cellIndex === openedCell) {
          if (openedType === 'multipack') {
            setOpenedCell(null);
          } else {
            setOpenedCell(cellIndex, 'multipack');
          }
        } else {
          setOpenedCell(cellIndex, 'multipack');
        }
      };
      return (
        <IconButton onClick={setRow}>
          <FontAwesome name="angle-down" type="fa" form="fa" />
        </IconButton>
      );
    },
    editable: false,
  },
  {
    title: 'More Info',
    keyName: 'supfit',
    align: 'center',
    sortBy: false,
    customComponent: function fitmentDropdown({
      setOpenedCell,
      openedCell,
      openedType,
      cellIndex,
    }) {
      const setRow = () => {
        if (cellIndex === openedCell) {
          if (openedType === 'fitment') {
            setOpenedCell(null);
          } else {
            setOpenedCell(cellIndex);
          }
        } else {
          setOpenedCell(cellIndex);
        }
      };
      return (
        <IconButton onClick={setRow}>
          <FontAwesome name="angle-down" type="fa" form="fa" />
        </IconButton>
      );
    },
    editable: false,
  },
];
