import type { AmazonReports } from 'types/channelTypes';

export const AMAZON = 'AMAZON';
export const AMAZON_ADS = 'AMAZON_ADS';
export const WALMART = 'WALMART';
export const EBAY = 'EBAY';
export const WOO_COMMERCE = 'WOO_COMMERCE';
export const SHOPIFY = 'SHOPIFY';

export const channelIds = {
  [AMAZON]: 1,
  [WALMART]: 2,
  [EBAY]: 3,
  [WOO_COMMERCE]: 4,
  [SHOPIFY]: 5,
};

export const STORE_NAMES = ['none', 'DZONE', 'ADS'];

export const colors = {
  [AMAZON]: '#f3a847',
  [WALMART]: '#0071dc',
  [EBAY]: '#86B817',
  [WOO_COMMERCE]: '#7f54b3',
  // [AMAZON]: 'rgb(243, 168, 71)',
  // [WALMART]: 'rgb(0, 113, 220)',
  // [EBAY]: 'rgb(134, 184, 23)',
  // [WOO_COMMERCE]: 'rgb(127, 84, 179)',
};

export const colorTheme = {
  palette: {
    [AMAZON]: { main: '#F3AC74', dark: '#d07730' },
    [WALMART]: { main: '#2188E5', dark: '#125a9b' },
    [EBAY]: { main: '#B4EDD2', dark: '#7da593' },
    [WOO_COMMERCE]: { main: '#5F37B1', dark: '#351f63' },
    [SHOPIFY]: { main: '#2FAC40', dark: '#1A5D23' },
    // [AMAZON]: { main: 'rgb(243, 168, 71)', },
    // [WALMART]: { main: 'rgb(0, 113, 220)', },
    // [EBAY]: { main: 'rgb(134, 184, 23)', },
    // [WOO_COMMERCE]: { main: 'rgb(127, 84, 179)', },
    contrastThreshold: 3,
    tonalOffset: 0.2,
    green: {
      main: '#0f0',
      light: '#0f0',
      dark: '#0f0',
      lightest: '#0f0',
      darkest: '#0f0',
      contrastText: '#fff',
    },
    orange: {
      main: '#FFA500',
      light: '#FFA500',
      dark: '#FFA500',
      lightest: '#FFA500',
      darkest: '#FFA500',
      contrastText: '#fff',
    },
    red: {
      main: '#f00',
      light: '#f00',
      dark: '#f00',
      lightest: '#f00',
      darkest: '#f00',
      contrastText: '#fff',
    },
    purple: {
      main: '#800080',
      light: '#800080',
      dark: '#800080',
      lightest: '#800080',
      darkest: '#800080',
      contrastText: '#fff',
    },
    blue: {
      main: '#00f',
      light: '#00f',
      dark: '#00f',
      lightest: '#00f',
      darkest: '#00f',
      contrastText: '#fff',
    },
    greys: {
      main: '#999',
      light: '#999',
      dark: '#999',
      lightest: '#999',
      darkest: '#999',
      contrastText: '#fff',
    },
    black: {
      main: '#000',
      light: '#000',
      dark: '#000',
      lightest: '#000',
      darkest: '#000',
      contrastText: '#fff',
    },
    light: '',
  },
};

export const CHANNEL_NAMES_BY_ID = {
  [channelIds[AMAZON]]: AMAZON,
  [channelIds[WALMART]]: WALMART,
  [channelIds[EBAY]]: EBAY,
  [channelIds[WOO_COMMERCE]]: WOO_COMMERCE,
  [channelIds[SHOPIFY]]: SHOPIFY,
};

export const AMAZON_NOTIFICATION_TYPES = [
  'ANY_OFFER_CHANGED',
  'MFN_ORDER_STATUS_CHANGE',
] as const;
export const EBAY_NOTIFICATION_TYPES = [] as const;
export const WALMART_NOTIFICATION_TYPES = [] as const;
export const WOO_COMMERCE_NOTIFICATION_TYPES = [] as const;

export const NOTIFICATION_TYPES = {
  [AMAZON]: AMAZON_NOTIFICATION_TYPES,
  [EBAY]: EBAY_NOTIFICATION_TYPES,
  [WALMART]: WALMART_NOTIFICATION_TYPES,
  [WOO_COMMERCE]: WOO_COMMERCE_NOTIFICATION_TYPES,
};

export const AMAZON_STATUS_MAP = {
  Active: 'ACTIVE',
  Inactive: 'INACTIVE',
  Incomplete: 'INCOMPLETE',
  // 'error': 'ERROR',
};
export const WALMART_STATUS_MAP = (lss: string, ps: string) => {
  const statuses = {
    // 'Active': 'ACTIVE',
    RETIRED: 'INACTIVE',
    ARCHIVED: 'INACTIVE',
    // 'error': 'ERROR',
  };
  if (ps === 'UNPUBLISHED') {
    return '';
  } else if (statuses[lss]) {
    return statuses[lss];
  } else {
    return lss.toUpperCase();
  }
};

export const AMAZON_REPORT_OPTIONS /* : Record<AmazonReports, AmazonReports> */ = {
  POST_INVENTORY_AVAILABILITY_DATA: 'POST_INVENTORY_AVAILABILITY_DATA',
  GET_MERCHANT_LISTINGS_ALL_DATA: 'GET_MERCHANT_LISTINGS_ALL_DATA',
  GET_MERCHANT_LISTINGS_DATA: 'GET_MERCHANT_LISTINGS_DATA',
  GET_MERCHANT_LISTINGS_INACTIVE_DATA: 'GET_MERCHANT_LISTINGS_INACTIVE_DATA',
  GET_MERCHANT_LISTINGS_DATA_BACK_COMPAT:
    'GET_MERCHANT_LISTINGS_DATA_BACK_COMPAT',
  GET_MERCHANT_LISTINGS_DATA_LITE: 'GET_MERCHANT_LISTINGS_DATA_LITE',
  GET_MERCHANT_LISTINGS_DATA_LITER: 'GET_MERCHANT_LISTINGS_DATA_LITER',
  POST_PRODUCT_PRICING_DATA: 'POST_PRODUCT_PRICING_DATA',
  RELIST_LISTINGS: `JSON_LISTINGS_FEED`,
  LISTING_SYNC: 'LISTING_SYNC',
  POST_ORDER_ACK_DATA: 'POST_ORDER_ACKNOWLEDGEMENT_DATA',
  POST_ORDER_ACK_DATA_FLAT: 'POST_FLAT_FILE_ORDER_ACKNOWLEDGEMENT_DATA',
};

export const EBAY_REPORT_OPTIONS = {
  LMS_ACTIVE_INVENTORY_REPORT: 'LMS_ACTIVE_INVENTORY_REPORT',
  FX_LISTING: 'FX_LISTING',
  LMS_ADD_ITEM: 'LMS_ADD_ITEM',
  LMS_REVISE_ITEM: 'LMS_REVISE_ITEM',
  LMS_REVISE_FIXED_PRICE_ITEM: 'LMS_REVISE_FIXED_PRICE_ITEM',
  UPDATE_FOUND_LISTINGS: 'UPDATE_FOUND_LISTINGS',
  LISTING_SYNC: 'LISTING_SYNC',
};

export const WALMART_REPORT_OPTIONS = {
  ITEM: 'ITEM',
  ITEM_PERFORMANCE: 'ITEM_PERFORMANCE',
  PRICE: 'price',
  MP_MAINTENANCE: 'MP_MAINTENANCE',
  LISTING_SYNC: 'LISTING_SYNC',
  WALMART_INVENTORY_UPLOAD: 'WALMART_INVENTORY_UPLOAD',
  WALMART_RECON_REPORT: 'WALMART_RECON_REPORT',
};

export const WOOCOMMERCE_REPORT_OPTIONS = {
  LISTING_REPORT: `${WOO_COMMERCE}_LISTING_REPORT`,
  PRICE_CHANGES: `${WOO_COMMERCE}_PRICE_CHANGES`,
  WOOCOMMERCE_INVENTORY_UPLOAD: 'WOOCOMMERCE_INVENTORY_UPLOAD',
};

export const EBAY_FEED_TYPES = {
  FX_LISTING: 'FX_LISTING',
  LMS_ADD_ITEM: 'LMS_ADD_ITEM',
  LMS_REVISE_ITEM: 'LMS_REVISE_ITEM',
  LMS_REVISE_FIXED_PRICE_ITEM: 'LMS_REVISE_FIXED_PRICE_ITEM',
  LMS_ACTIVE_INVENTORY_REPORT: 'LMS_ACTIVE_INVENTORY_REPORT',
};

const AMAZON_REASON_TYPES = [
  'NoInventory',
  'ShippingAddressUndeliverable',
  'CustomerExchange',
  'BuyerCanceled',
  'GeneralAdjustment',
  'CarrierCreditDecision',
  'RiskAssessmentInformationNotValid',
  'CarrierCoverageFailure',
  'CustomerReturn',
  'MerchandiseNotReceived',
  'CannotVerifyInformation',
  'PricingError',
  'RejectOrder',
  'WeatherDelay',
];

const EBAY_REASON_TYPES = [
  'NoReason'
];
const WALMART_REASON_TYPES = [
  'NoReason'
];
const WOOCOMMERCE_REASON_TYPES = [
  'NoReason'
];
const SHOPIFY_REASON_TYPES = [
  'NoReason'
];

export const AMAZON_ASIN_LINK = (asin) => {
  return `https://www.amazon.com/dp/${asin}`;
};
export const WALMART_PRODUCT_LINK = (id) => {
  return `https://www.walmart.com/ip/${id}`;
};
export const EBAY_PRODUCT_LINK = (id) => {
  return `https://www.ebay.com/itm/${id}`;
};
export const CHANNEL_REASONS_BY_ID = {
  [channelIds[AMAZON]]: AMAZON_REASON_TYPES,
  [channelIds[WALMART]]: WALMART_REASON_TYPES,
  [channelIds[EBAY]]: EBAY_REASON_TYPES,
  [channelIds[WOO_COMMERCE]]: WOOCOMMERCE_REASON_TYPES,
  [channelIds[SHOPIFY]]: SHOPIFY_REASON_TYPES,
};

export const SHOPIFY_GID_TYPES = [
  'InventoryItem',
  'Location',
  'Product',
  'ProductVariant',
  'Order',
  'FulfillmentOrder'
];

export const SHOPIFY_FULFILLMENT_ORDER = 'FulfillmentOrder';
export const SHOPIFY_ORDER = 'Order';
export const SHOPIFY_LOCATION = 'Location';
export const SHOPIFY_INVENTORY_ITEM = 'InventoryItem';
export const SHOPIFY_PRODUCT = 'Product';
export const SHOPIFY_PRODUCT_VARIANT = 'ProductVariant';
export const SHOPIFY_API_VERSION = '2024-10';