import { useEffect, useState } from 'react';
import { Grid, Typography, Divider } from '@mui/material';
import { makeStyles } from '@mui/styles';
import ReportApi from '@oneAppCore/services/ReportApi';
import { Props } from '../types';
import { primary } from '@src/Components/Theme/themes/constants';
import dateUtils from '@oneAppCore/utils/dates';
import { ContainerSASPermissions } from '@azure/storage-blob';

const useStyles = makeStyles({
  card: {
    display: 'flex',
    justify: 'space-between',
    alignItems: 'center',
    flexDirection: 'row',
    overflow: 'scroll',
  },
});

function SalesTrendsCard({ unitsBack, unitType }: Props) {
  const classes = useStyles();
  const [cardData, setCardData] = useState(null);
  const [pastData, setPastData] = useState(null);
  const [prevDays, setPrevDays] = useState(0);

  console.log(cardData, 'Sales Trends');

  useEffect(() => {
    (async () => {
      setCardData(null);
      const { newerThan, newerThan2, daysPrev } = dateUtils.getDaysBack({
        unitsBack,
        unitType,
      });
      setPrevDays(daysPrev);

      const response = await ReportApi.get('SALES_BY_CHANNEL', {
        newerThan,
        page: 1,
        limit: 1000000,
      });

      setCardData({ response });
      // setPastData({ pastResponse });
    })();
  }, [unitsBack]);

  return (
    <Grid style={{ paddingLeft: 10, paddingRight: 10 }}>
      <Typography variant="h5" style={{ color: '#616161', marginBottom: 10 }}>
        Sales Trends
      </Typography>
      <Grid
        key="top-prods-header"
        container
        style={{
          marginTop: 10,
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Grid item container xs={3}>
          <Typography variant="h6">Channel</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6">Sales</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6">Last Days</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="h6">Last Year</Typography>
        </Grid>
        {/* </Grid> */}
        <Grid xs={12}>
          <Divider style={{ background: primary.main, marginBottom: 5 }} />
        </Grid>
        {cardData?.response?.rows.map((row, i) => {
          return (
            <>
              <Grid key={`sales-trends-${i}`} container>
                <Grid item xs={3}>
                  <img height={35} width={35} src={row.logo} />
                </Grid>
                <Grid item xs={3}>
                  <Typography>
                    {/* ${row.current_days_total.toFixed(2)}
                     */}
                    {/* {Math.round(row.current_days_total) / 100000 > 999
                      ? `$${(
                          Math.round(row.current_days_total) / 100000
                        ).toFixed(2)}K`
                      : `$${row.current_days_total.toFixed(2)}`} */}
                    ${(Math.round(row?.current_days_total) / 100000).toFixed(2)}
                    K
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography>
                    {(Math.round(row?.previous_days_total) / 100000).toFixed(2)}
                    K
                  </Typography>
                  <div></div>
                </Grid>
                <Grid item xs={3}>
                  <Typography>
                    {(Math.round(row?.last_year_total) / 100000).toFixed(2)}K
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ marginBottom: 10 }}>
                  <Divider />
                </Grid>
              </Grid>
            </>
          );
        })}
      </Grid>
    </Grid>
  );
}

export default SalesTrendsCard;
