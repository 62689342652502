import dayjs from 'dayjs';

export const options = [
  {
    label: 'Today',
    value: 0,
  },
  {
    label: 'Yesterday',
    value: 1,
  },
  {
    label: 'Last 7 Days',
    value: 6,
  },
  {
    label: 'Last 14 Days',
    value: 13,
  },
  {
    label: 'Last 30 Days',
    value: 29,
  },
  {
    label: 'Last 60 Days',
    value: 59,
  },
  // {
  //   label: 'Last 90 Days',
  //   value: 89,
  // },
];
