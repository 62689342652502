import { ROLES, ADMIN, STORE_MANAGER } from '@oneAppCore/constants/roles';
import { Grid, Card, CardContent } from '@mui/material';
import { makeStyles } from '@mui/styles';
import recoilAnalyticsOptions from '@src/Components/Dashboards/Analytics/AnalyticsHooks/AnalyticsOptions';
import PlacedByChart from './PlacedByChart';
import OrdersByChannelChart from './OrdersByChannelChart';
import InternalDropshipChart from './InternalDropshipChart';
import ListingStatusReport from './ListingStatusReport';
import InventoryQuantityChart from './InventoryQuantityChart';
import IssueTypesChart from './IssueTypesChart';
import HeaderCards from './HeaderCards';
import SalesChart from './SalesChartOptions/SalesChartSelect';
import SalesChartOptions from './SalesChartOptions';
import SupplierCostReport from './SupplierCostReport';
import ShippingImpactChart from './ShippingImpactChart';
import AnalyticsOptions from './AnalyticsOptions';

const useStyles = makeStyles((theme) => ({
  paper: {
    padding: theme.spacing(2, 3),
  },
  bigPaper: {
    width: '100%',
  },
  pillPaperLeft: {
    padding: theme.spacing(2, 3),
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  pillPaperRight: {
    padding: theme.spacing(2, 3),
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
  },
}));

function AnalyticsDashboard(props: any) {
  const classes = useStyles();

  const { user } = props;
  const roles = user ? user.roles : [];
  const analyticsOptions = recoilAnalyticsOptions.useAnalyticsOptions();
  const adminRoles = [ROLES[ADMIN], ROLES[STORE_MANAGER]];
  const adminRole = roles
    .filter((role) => role.read || role.write || role.delete || role.create)
    .find((role) => adminRoles.includes(role.roleId));
  return (
    <Grid container spacing={2}>
      {adminRole?.read === true && (
        <>
          <Grid item container>
            <AnalyticsOptions />
          </Grid>
          <Grid item container>
            <HeaderCards
              supplierId={analyticsOptions.supplierId}
              unitsBack={analyticsOptions.newerThan}
            />
          </Grid>

          <Grid item xs={12}>
            <Card className={classes.paper}>
              <CardContent>
                <SalesChart />
                <SalesChartOptions />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.paper}>
              <CardContent>
                <ShippingImpactChart unitsBack={analyticsOptions?.newerThan} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.paper}>
              <CardContent>
                <InternalDropshipChart unitsBack={analyticsOptions.newerThan} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.paper}>
              <CardContent>
                <SupplierCostReport unitsBack={analyticsOptions.newerThan} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.paper}>
              <CardContent>
                <ListingStatusReport unitsBack={analyticsOptions.newerThan} />
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12}>
            <Card className={classes.paper}>
              <CardContent>
                <InventoryQuantityChart
                  unitsBack={analyticsOptions.newerThan}
                />
              </CardContent>
            </Card>
          </Grid>
        </>
      )}
      <Grid item xs={12}>
        <Card className={classes.paper}>
          <CardContent>
            <IssueTypesChart unitsBack={analyticsOptions.newerThan} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card className={classes.paper}>
          <CardContent>
            <PlacedByChart unitsBack={analyticsOptions.newerThan} />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} md={6}>
        <Card className={classes.paper}>
          <CardContent>
            <OrdersByChannelChart unitsBack={analyticsOptions.newerThan} />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default AnalyticsDashboard;
