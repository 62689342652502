import { useMemo, useState, useEffect } from 'react';
import useSuppliers from '@src/hooks/swr/useSuppliers';

import { columns, stockProperties } from './constants';

import Grid from '@mui/material/Grid';
import ManufacturerSuppliersApi from '@oneAppCore/services/ManufacturerSuppliersApi';
import SearchView from '@src/Components/common/containers/SearchView';
import SearchGrid from '@src/Components/common/containers/SearchGrid';
import ProgressModal from './ProgressModal';
import MiniReports from './MiniReports';
import { Filters } from '@src/Components/common/containers/SearchView/types';
import useVehicleMake from '@src/hooks/swr/useVehicleMake';
import CreateButton from '@src/Components/Products/Search/CreateButton';
const url = '/api/v1/manufacturer-suppliers/search';

function AdminInventorySearch() {
  const { data: suppliers = [] } = useSuppliers();
  const [filters, setFilters] = useState<Filters[]>([]);
  const { data: vehicleMakes = [] } = useVehicleMake();
  const makeOptions = vehicleMakes.map((make) => ({
    label: make.name,
    value: make.id,
  }));
  const currentYear = new Date().getUTCFullYear();
  const years = Array.from({ length: 100 }, (_, i) => currentYear - i);
  const yearsProps = years.map((year) => ({
    label: year.toString(),
    value: year.toString(),
  }));

  const [openPro, setOpenPro] = useState(false);

  const supplierOptions = useMemo(
    () =>
      suppliers.map((supplier) => ({
        label: supplier.name,
        value: supplier.id,
      })),
    [suppliers],
  );

  const internalSuppliers = useMemo(
    () =>
      suppliers
        .filter(
          ({ internalSupplier, internalStock }) =>
            internalSupplier && internalStock,
        )
        .map((supplier) => supplier.id),
    [suppliers],
  );

  useEffect(() => {
    const mpnFromLocalStorage = localStorage.getItem('selectedVarMpn') || '';
    if (mpnFromLocalStorage) {
      setFilters((prevFilters) => {
        const updatedFilters = prevFilters.map((filter) => {
          if (filter.keyName === 'manufacturerNo') {
            return {
              ...filter,
              defaultValue: mpnFromLocalStorage,
            };
          }
          return filter;
        });
        return updatedFilters;
      });
    }
  }, []);

  const initialFilters: Filters[] = [
    {
      type: 'string',
      name: 'MPN',
      keyName: 'manufacturerNo',
    },
    {
      type: 'selector',
      name: 'Supplier',
      keyName: 'supplierId',
      properties: supplierOptions,
    },
    {
      type: 'selector',
      name: 'Stock Status',
      keyName: 'stockFilter',
      properties: stockProperties,
    },
    {
      type: 'string',
      name: 'Locations',
      keyName: 'location',
    },
    {
      type: 'selector',
      name: 'Make',
      keyName: 'fitment.make',
      properties: makeOptions,
    },
    {
      type: 'string',
      name: 'Model',
      keyName: 'fitment.model',
    },
    {
      type: 'multiSelector',
      name: 'Year',
      keyName: 'fitment.years',
      properties: yearsProps,
    },
    {
      type: 'string',
      name: 'Brand',
      keyName: 'brand',
    },
    {
      type: 'equality',
      name: 'Days Aged',
      keyName: 'daysLeftFilter',
    },
  ];

  const combinedFilters = initialFilters.map((filter) => {
    const updatedFilter = filters.find((f) => f.keyName === filter.keyName);
    return updatedFilter ? updatedFilter : filter;
  });

  const customSearchBarComponents = (
    <Grid
      item
      container
      xs={12}
      sm={10}
      md={6}
      lg={2}
      spacing={1}
      style={{ justifyContent: 'space-around', paddingLeft: 8 }}
    >
      <Grid item container xs={12}>
        <CreateButton />
      </Grid>
    </Grid>
  );

  return (
    <Grid
      item
      container
      justifyContent="center"
      alignItems="center"
      spacing={1}
    >
      <Grid
        item
        container
        justifyContent="center"
        alignItems="center"
        spacing={1}
      >
        <Grid
          item
          container
          justifyContent="flex-end"
          style={{ marginBottom: 15 }}
        >
          <ProgressModal open={openPro} close={setOpenPro} />
        </Grid>
      </Grid>
      <SearchGrid
        url={url}
        api={ManufacturerSuppliersApi}
        MiniReportComponent={MiniReports}
        filters={combinedFilters}
        columns={columns(internalSuppliers)}
        editableColumns={['quantity', 'location']}
        supportsDownload
        customSearchBarComponents={customSearchBarComponents}
      />
      {/* <SearchViewc
        url={url}
        customSearchBarComponents={customSearchBarComponents}
        supportsDownload
        columns={columns}
        filters={combinedFilters}
        MiniReportComponent={MiniReports}
        showInventoryOptions
      /> */}
    </Grid>
  );
}

export default AdminInventorySearch;
