import { useMemo } from 'react';
import useSuppliers from '@src/hooks/swr/useSuppliers';

import AppSelect from '../AppSelect';

import type { Props } from './types';

function SupplierSelect(props: Props) {
  const { data: suppliers } = useSuppliers();

  const options = useMemo(() => {
    if (!suppliers) return [];
    return suppliers.map((channel) => ({
      value: channel.id,
      label: channel.name,
    }));
  }, [suppliers]);

  return (
    <AppSelect
      style={{ overflowY: 'scroll' }}
      options={options}
      label={props?.label || 'Supplier'}
      {...props}
      value={
        typeof props?.value === 'object'
          ? props.value
          : options.find((option) => option.value === props?.value) || {}
      }
    />
  );
}

export default SupplierSelect;
