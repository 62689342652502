import { useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  Divider,
} from '@mui/material';
import ReportApi from '@oneAppCore/services/ReportApi';
import { Props } from '../types';
import { formatCentsToDollarsIntl } from '@oneAppCore/utils/currency';
import { primary } from '@src/Components/Theme/themes/constants';
import dateUtils from '@oneAppCore/utils/dates';

function LabelCounts({ unitsBack, unitType }: Props) {
  const [cardData, setCardData] = useState(null);
  const [topProducts, setTopProducts] = useState(null);

  useEffect(() => {
    (async () => {
      setCardData(null);
      const { newerThan } = dateUtils.getDaysBack({ unitsBack, unitType });
      const response = await ReportApi.get('SHIP_STATION_FEE', {
        newerThan,
        page: 1,
        limit: 20,
      });
      setCardData({ response });
    })();
  }, [unitsBack]);

  return (
    <Grid style={{ paddingLeft: 10, paddingRight: 10 }}>
      <Typography variant="h5" style={{ color: '#616161', marginBottom: 10 }}>
        Labels Made
      </Typography>
      <Grid key="labels-header" container style={{ marginTop: 10 }}>
        <Grid item xs={4}>
          <Typography variant="h6" title="Total number of labels made">Count</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" title="Ten cent fee per label">Fees</Typography>
        </Grid>
        <Grid item xs={4}>
          <Typography variant="h6" title="Total added cost for all labels">Total</Typography>
        </Grid>
      </Grid>
      <Grid xs={12}>
        <Divider style={{ background: primary.main, marginBottom: 5 }} />
      </Grid>

      {cardData?.response?.rows.map((elem, index) => {
        return (
          <>
            <Grid key={`labels-${index + 1}`} container style={{ marginTop: 10 }}>
              <Grid item xs={4}>
                <Typography title="Total number of labels made" key={`${index + 1}-label-id`}>
                  {Number(elem.total).toLocaleString()}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography title="Ten cent fee per label">{formatCentsToDollarsIntl(elem.totalcents)}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography title="Total added cost for all labels">{formatCentsToDollarsIntl(elem.totalCost)}</Typography>
              </Grid>
            </Grid>
            {cardData?.response?.rows?.length !== index + 1 && (
              <Grid xs={12}>
                <Divider style={{ marginTop: 5 }} />
              </Grid>
            )}
          </>
        );
      })}
    </Grid>
  );
}

export default LabelCounts;
