function phoneNumber(phone?: string) {
  if (!phone) return null;
  const [withoutExtension, extension] = phone.split(' ext.');
  const cleaned = ('' + withoutExtension).replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const formattedNumber = `${match[1] ? '+1 ' : ''}(${match[2]}) ${match[3]}-${match[4]}${extension ? ` ext. ${extension.trim()}` : ''}`;
    return formattedNumber;
  }
  return null;
}
function classNames(arrayofClassNames: any) {
  if (Array.isArray(arrayofClassNames)) {
    return arrayofClassNames.join(' ');
  } else {
    return [arrayofClassNames].join(' ');
  }
}
export const unEscapedJSON = (jsonString: string, reduce: boolean = false) => {
  let result;
  if (reduce) {
    result = jsonString.replaceAll(/\\\\/gm, '\\');
  } else {
    result = jsonString.replaceAll(/\\/gm, '\\\\');
  }
  return result;
}
export const walmartEscapeBackslashSku = (sku: string) => {
  const result = sku.replaceAll(/\\/gm, '//');
  return result;
}

export const addSpaceBeforeCapital = (str: string) => {
  const result = str.replace(/([A-Z])/g, ' $1').trim();
  return result;
}

// adapted from https://stackoverflow.com/questions/29976532/how-to-transform-a-list-of-numbers-into-a-list-of-ranges-of-consecutive-numbers?noredirect=1#comment48072763_29976532
export const numberGrouper = ({ years, sorted, formatResult, optionalConcatenation = '' }: { years: number[], sorted?: boolean, formatResult?: boolean, optionalConcatenation?: string }): number[][] | string => {
  const input = years;
  if (!sorted) input.sort((a, b) => a - b);
  // list of ranges to be output, start with the first element of the input array
  const ranges = [[input[0], input[0]]];
  let lastIndex = 0;
  for (var index = 1; index < input.length; index++) {
    // if the current element is 1 away from the end of whichever range
    // we're currently in
    if (input[index] - ranges[lastIndex][1] === 1) {
      // update the end of that range to be this number
      ranges[lastIndex][1] = input[index];
    } else {
      // otherwise, add a new range to ranges
      ranges[++lastIndex] = [input[index], input[index]];
    }
  }
  const stringJoiner = optionalConcatenation ? optionalConcatenation : ', ';
  const formattedRanges = ranges.map((yb) => (yb[1] !== yb[0] ? `${yb[0]}-${yb[1]}` : yb[0])).join(stringJoiner);
  return formatResult ? formattedRanges : ranges;
};

type CapsOptions = {
  spaces?: boolean;
  caps?: boolean
};
const capsOptDefault = {};
export const getStartLetters = (inputString: string = '', options: CapsOptions = capsOptDefault) => {
  // if (!inputString) throw 'Empty string input to formatter "getCapsFromString"';
  const { spaces, caps } = options;
  const inputMatched = inputString.match(/\b(\w)/g) || [];
  let inputJoined = inputMatched.join(spaces ? ' ' : '')
  if (caps) {
    inputJoined = inputJoined.toUpperCase();
  }
  const output = inputJoined;
  return output;
};

export const capitalize = (inputString: string) => {
  const splitString = inputString.split(' ');
  const capArray = splitString.map((st) => {
    const cap = `${st[0].toUpperCase()}${st.toLowerCase().slice(1)}`;
    return cap;
  });
  return capArray.join(' ');
}

export default {
  phoneNumber,
  classNames,
};
