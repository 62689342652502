import { useState, FormEvent, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import useSuppliers from '@src/hooks/swr/useSuppliers';
import useQuery from '@src/hooks/util/useQuery';
import { useHistory } from 'react-router-dom';

import ManufacturerSuppliersApi from '@oneAppCore/services/ManufacturerSuppliersApi';
import { columns } from './constants';
import queryUtils from '@src/utils/queries';

import { Grid, Typography, Button, SwipeableDrawer } from '@mui/material';
import { Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import { TextField, Select, Backdrop } from '@src/Components/common';

import type { FormProps } from '@src/Components/common/containers/SearchView/CreationDrawer/types';
import type { ManufacturerSupplier } from '@oneAppCore/types/supplierTypes';
import useMe from '@src/hooks/swr/useMe';
import { ROLES, ADMIN } from '@oneAppCore/constants/roles';
import { omit } from 'lodash';
import type { DrawerProps } from './types';

const useStyles = makeStyles((theme: Theme) => ({
  form: {
    paddingTop: theme.spacing(1),
    width: '100%',
  },
  drawerPaper: {
    // width: 400,
    [theme.breakpoints.up('md')]: {
      // width: ({ mobile }: StyleProps) => (mobile ? mobileWidth : drawerWidth),
      flexShrink: 0,
      width: '360px',
    },
    [theme.breakpoints.down('md')]: {
      width: '350px',
    },
    padding: theme.spacing(2),
    justifyContent: 'flex-start',
  },
  title: { paddingTop: theme.spacing(1) },
  root: {
    display: 'flex',
    flexBasis: 0,
    margin: theme.spacing(3, 0),
  },
  // typography: {
  //   marginLeft: 10,
  //   marginTop: 5,
  //   fontSize: 14,
  // },
  // button: {
  //   margin: 5,
  // },
  divider: {
    marginTop: 10,
    marginBottom: 10,
  },
  // spacing: {
  //   marginTop: 5,
  // },
}));

function InventoryDrawer({
  isDrawerOpen,
  setDrawerOpen,
  row,
  mobile,
  invData,
  isInternal,
}: DrawerProps & { isInternal?: boolean }) {
  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles({ mobile });
  const { data: user } = useMe();

  const [form, setForm] = useState<any>({});
  const [saving, setSaving] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const { data: suppliers = [] } = useSuppliers();

  const history = useHistory();

  useEffect(() => {
    const userRoles = user.roles;
    for (let index = 0; index < userRoles.length; index++) {
      const element = userRoles[index];
      if (
        element.roleId == ROLES[ADMIN] &&
        (element.read || element.write || element.update || element.delete)
      ) {
        setIsAdmin(true);
        break;
      }
    }
  }, []);

  useEffect(() => {
    // Check if row is provided, if not, populate form with invData
    if (!row && invData) {
      let cheapestSupplier = { supplierId: null, totalCost: Infinity };
      if (invData?.result?.mansups?.length > 0) {
        invData?.result?.mansups?.forEach((item) => {
          const totalCost =
            item.cost +
            (item.shippingCost
              ? item.shippingCost
              : item.estimatedShipping
                ? item.estimatedShipping
                : 0);
          if (totalCost < cheapestSupplier.totalCost) {
            cheapestSupplier = { supplierId: item.supplierId, totalCost };
          }
        });
      }
      setForm({ ...invData.result, supplierId: cheapestSupplier.supplierId });
    } else {
      if (row?.id) {
        (async () => {
          const response = await ManufacturerSuppliersApi.getById(
            Number(row?.id),
          );

          setForm({ ...response });
        })();
      } else {
        setForm({});
      }
    }
  }, [row, invData]);

  const update = (
    key: string | string[],
    value: string | number | string[] | number[],
  ) => {
    const kia = Array.isArray(key); //key is array
    const via = Array.isArray(value); //value is array
    let updateForm = { ...form };
    const arrayKeys = ['barcodes'];

    if (Array.isArray(key) && via) {
      key.forEach((ky, index) => {
        updateForm[ky] = value[index];
      });
    } else if (!Array.isArray(key) && arrayKeys.includes(key)) {
      updateForm[key] = value;
    } else {
      const ky = Array.isArray(key) ? key[0] : key;
      const vl = via ? via[0] : value;
      updateForm[ky] = vl;
    }
    setForm(updateForm);
  };

  const submit = async (e: FormEvent) => {
    e.stopPropagation();
    e.preventDefault();
    setSaving(true);
    try {
      let omitList = ['variationOptions', 'variation', 'internalSupplier'];

      interface UpdateFormData {
        id?: number;
        sku?: string;
        mansups?: any[];
        supplierId?: string;
        variationId?: number;
        cost?: number;
      }

      const updateForm: UpdateFormData = {
        ...omit(form, omitList),
        supplierId: form?.supplierId
          ? form?.supplierId
          : form?.mansups?.[0]?.supplierId,
        variationId: form?.variationId
          ? form.variationId
          : form?.mansups?.[0]?.variationId,
        cost: form?.cost ? form.cost : form?.mansups?.[0]?.cost,
      };
      const { id, sku, mansups, ...postData } = updateForm;

      if (row?.id) {
        // Update existing record
        await ManufacturerSuppliersApi.put(Number(row?.id), postData);
        enqueueSnackbar(`Updated Manufacturer Supplier`, {
          variant: 'success',
        });
      } else {
        // Create new record
        await ManufacturerSuppliersApi.post(postData);
        enqueueSnackbar(`Created Manufacturer Supplier`, {
          variant: 'success',
        });
      }
      // Close the drawer
      setDrawerOpen();
    } catch (e) {
      enqueueSnackbar(`${e.message || e}`, {
        variant: 'error',
      });
    } finally {
      setSaving(false);
    }
  };

  const clearForm = () => {
    setForm({});
  };

  const deleteSelectedManufacturerSupplier = async (id: number | string) => {
    try {
      if (window.confirm('Are you sure you want to delete?')) {
        // user clicked "OK"
        const deleted = await ManufacturerSuppliersApi.deleteById(id);
        enqueueSnackbar(deleted, { variant: 'success' });
        setDrawerOpen();
      } else {
        // user clicked cancel
        // do nothing
      }
    } catch (e) {
      enqueueSnackbar(e, { variant: 'error' });
    }
  };

  const deleteUpcToVariation = async (upcId: number) => {
    try {
      const deleted = await ManufacturerSuppliersApi.deleteUPCToVariation(
        upcId,
      );
      setDrawerOpen();
      enqueueSnackbar(deleted, { variant: 'success' });
    } catch (e) {
      enqueueSnackbar(e, { variant: 'error' });
    }
  };

  return (
    <SwipeableDrawer
      open={isDrawerOpen}
      onClose={() => {
        clearForm();
        setDrawerOpen();
      }}
      onOpen={() => null}
      classes={{
        paper: classes.drawerPaper,
      }}
      anchor="right"
    >
      <Grid container>
        <Grid alignItems="center" justifyContent="center" container>
          <Typography
            variant="h4"
            style={{ marginBottom: 15, fontWeight: 600 }}
          >
            Inventory Drawer
          </Typography>
        </Grid>
        <Grid container item xs={12}>
          {columns(isInternal).map((column) => {
            if (column.customComponent) {
              return (
                <Grid
                  item
                  xs={column.width ? column.width : 12}
                  key={column.keyName}
                  style={{ marginTop: 10 }}
                >
                  {column.customComponent(form, update, suppliers, invData)}
                </Grid>
              );
            } else {
              return (
                <Grid
                  item
                  xs={12}
                  key={column.keyName}
                  style={{ marginTop: 10 }}
                >
                  <TextField
                    id={column.keyName}
                    label={column.title}
                    type={column.type}
                    defaultValue={
                      form?.[column.keyName] !== undefined
                        ? form?.[column.keyName]
                        : invData?.result?.mansups?.[0]?.[column.keyName] ??
                        invData?.result?.[column.keyName] ??
                        ''
                    }
                    value={
                      form?.[column.keyName] !== undefined
                        ? form?.[column.keyName]
                        : invData?.result?.mansups?.[0]?.[column.keyName] ??
                        invData?.result?.[column.keyName] ??
                        ''
                    }
                    onChange={(e) =>
                      column.type === 'number'
                        ? update(
                          column.keyName,
                          e.currentTarget.value !== ''
                            ? Number(e.currentTarget.value)
                            : 0,
                        )
                        : update(column.keyName, e.currentTarget.value)
                    }
                    fullWidth
                  />
                </Grid>
              );
            }
          })}
        </Grid>
        <Grid container item xs={12} justifyContent="space-between">
          <Grid
            item
            container
            justifyContent="space-between"
            style={{ marginTop: 15 }}
          >
            <Grid item xs={5}>
              <Button
                variant="contained"
                style={{ backgroundColor: 'crimson', color: 'white' }}
                fullWidth
                onClick={() => {
                  clearForm();
                  setDrawerOpen();
                }}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item xs={5}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={(e) => {
                  submit(e);
                }}
              >
                Save
              </Button>
            </Grid>
          </Grid>
          <Grid item container xs={12} style={{ marginTop: 15 }}>
            {row?.id && isAdmin && (
              <Button
                variant="contained"
                style={{ backgroundColor: 'red', color: 'white' }}
                fullWidth
                onClick={() => {
                  deleteSelectedManufacturerSupplier(row?.id);
                }}
              >
                Delete Item
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
    </SwipeableDrawer>
  );
}

export default InventoryDrawer;
