type FitmentTable = {
  title: string;
  keyName: string;
  customComponent?: (row: any) => JSX.Element;
}[];

export const fitmentTable: FitmentTable = [
  {
    title: 'Year',
    keyName: 'year',
    customComponent: function yearGen(row: number[]) {
      if (row?.length > 1) {
        const sortedRow = row.sort();
        return (
          <div>
            {sortedRow?.[0]}-{sortedRow[sortedRow.length - 1]}
          </div>
        );
      } else return <div>{row?.[0]}</div>;
    },
  },
  {
    title: 'Make',
    keyName: 'make',
  },
  {
    title: 'Model',
    keyName: 'model',
  },
  {
    title: 'Edit',
    keyName: 'edit',
  },
];
