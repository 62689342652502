import React from 'react';
import {
  Chip,
  Grid,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableContainer,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import {
  supplierIds,
  WPS,
  AD,
  RMATV,
  MOTO,
  DZONE,
} from '@oneAppCore/constants/suppliers';
import SupplierOrderButton from '@src/Components/Orders/SupplierOrderButton';
import { formatCentsToDollars } from '@oneAppCore/utils/currency';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import { makeStyles } from '@mui/styles';
import { getColor } from '@src/utils/colors';

const useStyles = makeStyles(() => ({
  image: {
    height: 50,
    width: 50,
    marginBottom: 10,
    marginTop: 10,
  },
  leadColumn: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  unavailable: {
    color: getColor('red', 'main'),
  },
  available: {
    color: getColor('green', 'main'),
  },
  notLowestPrice: {
    color: getColor('red', 'main'),
  },
  lowestPrice: {
    color: getColor('green', 'main'),
  },
  empty: {
    height: 50,
    width: 50,
    marginBottom: 10,
    marginTop: 10,
  },
}));
function OrderItemsRows({
  row,
  isLoading,
  supplierRadioSelection,
  onRadioChange,
  totals,
  calculatedResponse,
}) {
  const { orderItems } = row;
  const classes = useStyles();
  let available = true;
  if (!orderItems[0].allSuppliersArray.length) {
    return (
      <TableRow key={orderItems[0].id}>
        <TableCell className={classes.leadColumn}>
          {orderItems[0].variationImages?.[0] ? (
            <img
              className={classes.image}
              src={orderItems[0].variationImages?.[0].imageUrl}
            />
          ) : (
            <Grid>
              <img
                className={classes.image}
                src={`https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`}
                alt="placeholder image for product image"
              />
            </Grid>
          )}
          <Grid></Grid>
        </TableCell>
        <TableCell>
          <Grid>
            <b>Supplier: </b>
            {orderItems[0].supplierId === supplierIds[WPS]
              ? 'WPS'
              : orderItems[0].supplierId === supplierIds[AD]
              ? 'AD'
              : orderItems[0].supplierId === supplierIds[RMATV]
              ? 'RMATV'
              : orderItems[0].supplierId === supplierIds[DZONE]
              ? 'DZONE'
              : orderItems[0].supplierId === supplierIds[MOTO]
              ? 'MU'
              : `${supplierIds[orderItems[0].supplierId]}`}
          </Grid>
          <Grid>
            <b>SKU: </b>
            {orderItems[0].sku}
          </Grid>
          <Grid>
            <b>Qty: </b>
            {orderItems[0].quantity}
          </Grid>
        </TableCell>
        <TableCell>
          <Grid>
            <b>Cost: </b>
            {orderItems[0].supplierSubTotal > 0
              ? formatCentsToDollars(orderItems[0].supplierSubTotal)
              : 'NO SUPPLIER'}
          </Grid>
          <Grid>
            <b>Ship: </b>
            {row.supplierShippingCost > 0
              ? formatCentsToDollars(row.supplierShippingCost)
              : orderItems[0].supplierShippingCost > 0
              ? formatCentsToDollars(orderItems[0].supplierShippingCost)
              : 'NO SUPPLIER'}
          </Grid>
          <Grid>
            <b>Total: </b>
            {row.supplierShippingCost > 0 && orderItems[0].supplierSubTotal > 0
              ? formatCentsToDollars(
                  row.supplierShippingCost + orderItems[0].supplierSubTotal,
                )
              : orderItems[0].supplierShippingCost > 0 &&
                orderItems[0].supplierSubTotal > 0
              ? formatCentsToDollars(
                  orderItems[0].supplierShippingCost +
                    orderItems[0].supplierSubTotal,
                )
              : 'NO SUPPLIER'}
          </Grid>
        </TableCell>
      </TableRow>
    );
  }
  if (
    (row.store === 'dzone' || row.store === 'ads') &&
    (row.orderStatus === 'UNASSIGNED' || row.orderStatus === 'ISSUE')
  ) {
    if (orderItems.length === 1) {
      const { allSuppliersArray } = orderItems[0];
      const totalsArray = totals
        .filter((e) => e?.total !== null && e?.total !== 0)
        .map((e) => e.total);
      return allSuppliersArray[0].supplierId.map((id, index) => {
        const supplierTotalObj = totals.find(
          (e) => e.supplier === allSuppliersArray[0].name[index],
        );
        const available =
          orderItems[0].supplierName === allSuppliersArray[0].name[index]
            ? true
            : supplierTotalObj.available
            ? true
            : orderItems[0].quantity > allSuppliersArray[0].available[index]
            ? false
            : true;
        const supplierTotal = supplierTotalObj.total;

        return (
          <TableRow key={id}>
            <TableCell className={classes.leadColumn}>
              <SupplierOrderButton
                row={row}
                supplier={allSuppliersArray[0].name[index]}
                isLoading={isLoading}
                supplierRadioSelection={supplierRadioSelection}
                onRadioChange={onRadioChange}
                available={available}
              />
              {allSuppliersArray[0].name[index] === AD ? (
                <Grid>
                  <Chip
                    style={{
                      color: calculatedResponse
                        ? getColor('purple', 'main')
                        : '#ffffff',
                      width: 'fit-content',
                      height: '100%',
                    }}
                    label={calculatedResponse ? 'Calculated' : 'Awaiting'}
                  />
                </Grid>
              ) : null}
            </TableCell>
            <TableCell>
              <Grid>
                <b>Supplier: </b>
                {allSuppliersArray[0].name[index] === WPS
                  ? 'WPS'
                  : allSuppliersArray[0].name[index] === AD
                  ? 'AD'
                  : allSuppliersArray[0].name[index] === RMATV
                  ? 'RMATV'
                  : allSuppliersArray[0].name[index] === DZONE
                  ? 'DZ'
                  : allSuppliersArray[0].name[index]}
              </Grid>
              <Grid>
                <b>SKU: </b>
                {orderItems[0].sku}
              </Grid>
              <Grid
                className={
                  orderItems[0].supplierName ===
                  allSuppliersArray[0].name[index]
                    ? classes.available
                    : orderItems[0].quantity >
                      allSuppliersArray[0].available[index]
                    ? classes.unavailable
                    : classes.available
                }
              >
                <b>Qty: </b>
                {orderItems[0].quantity}
                <b> Avail: </b>
                {orderItems[0].supplierName === allSuppliersArray[0].name[index]
                  ? allSuppliersArray[0].available[index] +
                    orderItems[0].quantity
                  : allSuppliersArray[0].available[index]}
              </Grid>
            </TableCell>
            <TableCell>
              <Grid>
                <b>Cost: </b>
                {calculatedResponse && allSuppliersArray[0].name[index] === AD
                  ? '-'
                  : formatCentsToDollars(supplierTotalObj.totalItemCost)}
              </Grid>

              <Grid>
                <b>Ship: </b>
                {calculatedResponse && allSuppliersArray[0].name[index] === AD
                  ? '-'
                  : formatCentsToDollars(supplierTotalObj.shippingCost)}
              </Grid>
              <Grid
                className={
                  !supplierTotal
                    ? classes.notLowestPrice
                    : supplierTotal <= Math.min(...totalsArray)
                    ? classes.lowestPrice
                    : classes.notLowestPrice
                }
              >
                <b>Total: </b>
                {formatCentsToDollars(supplierTotal)}
              </Grid>
            </TableCell>
          </TableRow>
        );
      });
    } else {
      const totalWeight = orderItems.reduce(
        (total, acc) => acc.quantity * acc.allSuppliersArray[0].weight + total,
        0,
      );

      const totalsArray = totals.map((e) =>
        e.calculatedResponse
          ? e.total
          : totalWeight > 5
          ? orderItems.length * e.shippingCost + e.totalItemCost
          : e.total,
      );

      return orderItems[0].allSuppliersArray[0].name.map(
        (supplier, supplierIndex) => {
          const itemsForSupplier = orderItems.filter(
            (item) =>
              item.allSuppliersArray[0].name[supplierIndex] === supplier,
          );
          const supplierTotalObj = totals.find((e) => e.supplier === supplier);
          const totalCost = supplierTotalObj.calculatedResponse
            ? supplierTotalObj.total
            : totalWeight > 5
            ? orderItems.length * supplierTotalObj.shippingCost +
              supplierTotalObj.totalItemCost
            : supplierTotalObj.total;

          return (
            <React.Fragment key={supplierIndex}>
              {itemsForSupplier.map((item, itemIndex) => (
                <TableRow key={itemIndex}>
                  {
                    (available =
                      item.supplierName ===
                      item.allSuppliersArray[0].name[supplierIndex]
                        ? available
                        : item.quantity >
                          item.allSuppliersArray[0].available[supplierIndex]
                        ? false
                        : available)
                  }
                  <TableCell className={classes.leadColumn}>
                    {orderItems[itemIndex].variationImages?.[0] ? (
                      <Grid
                        onClick={() => {
                          (supplier !== AD && supplier !== WPS) ||
                          !available ||
                          isLoading
                            ? null
                            : onRadioChange(supplier);
                        }}
                      >
                        <img
                          className={classes.image}
                          src={
                            orderItems[itemIndex].variationImages?.[0].imageUrl
                          }
                        />
                      </Grid>
                    ) : (
                      <Grid
                        onClick={() => {
                          (supplier !== AD && supplier !== WPS) ||
                          !available ||
                          isLoading
                            ? null
                            : onRadioChange(supplier);
                        }}
                      >
                        <img
                          className={classes.image}
                          src={`https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`}
                          alt="placeholder image for product image"
                        />
                      </Grid>
                    )}
                  </TableCell>
                  <TableCell>
                    <b>Supplier: </b>
                    {supplier === WPS
                      ? 'WPS'
                      : supplier === AD
                      ? 'AD'
                      : supplier === RMATV
                      ? 'RMATV'
                      : supplier === DZONE
                      ? 'DZ'
                      : supplier}
                    <Grid>
                      <b>SKU: </b>
                      {item.sku}
                    </Grid>
                    <Grid
                      className={
                        item.supplierName ===
                        item.allSuppliersArray[0].name[supplierIndex]
                          ? classes.available
                          : item.quantity >
                            item.allSuppliersArray[0].available[supplierIndex]
                          ? classes.unavailable
                          : classes.available
                      }
                    >
                      <b>Qty: </b>
                      {item.quantity}
                      <b> Avail: </b>
                      {item.supplierName ===
                      item.allSuppliersArray[0].name[supplierIndex]
                        ? item.allSuppliersArray[0].available[supplierIndex] +
                          item.quantity
                        : item.allSuppliersArray[0].available[supplierIndex]}
                    </Grid>
                  </TableCell>
                  <TableCell>
                    <Grid>
                      <b>Cost: </b>
                      {calculatedResponse && supplier === AD
                        ? '-'
                        : formatCentsToDollars(
                            item.allSuppliersArray[0].cost[supplierIndex],
                          )}
                    </Grid>

                    <Grid>
                      <b>Ship: </b>
                      {calculatedResponse && supplier === AD
                        ? '-'
                        : item.allSuppliersArray[0].estimatedShipping[
                            supplierIndex
                          ] > 0 &&
                          parseFloat(item.allSuppliersArray[0].weight) <= 5
                        ? formatCentsToDollars(
                            item.allSuppliersArray[0].estimatedShipping[
                              supplierIndex
                            ],
                          )
                        : item.allSuppliersArray[0].estimatedShipping[
                            supplierIndex
                          ] > 0 &&
                          parseFloat(item.allSuppliersArray[0].weight) > 5
                        ? formatCentsToDollars(
                            item.quantity *
                              item.allSuppliersArray[0].estimatedShipping[
                                supplierIndex
                              ],
                          )
                        : item.allSuppliersArray[0].shippingCost[
                            supplierIndex
                          ] > 0 &&
                          parseFloat(item.allSuppliersArray[0].weight) <= 5
                        ? formatCentsToDollars(
                            item.allSuppliersArray[0].shippingCost[
                              supplierIndex
                            ],
                          )
                        : item.allSuppliersArray[0].shippingCost[
                            supplierIndex
                          ] > 0 &&
                          parseFloat(item.allSuppliersArray[0].weight) > 5
                        ? formatCentsToDollars(
                            item.quantity *
                              item.allSuppliersArray[0].shippingCost[
                                supplierIndex
                              ],
                          )
                        : '$0.00'}
                    </Grid>
                    <Grid>
                      <b>Total: </b>
                      {calculatedResponse && supplier === AD
                        ? '-'
                        : item.allSuppliersArray[0].estimatedShipping[
                            supplierIndex
                          ] > 0 &&
                          parseFloat(item.allSuppliersArray[0].weight) <= 5
                        ? formatCentsToDollars(
                            item.quantity *
                              item.allSuppliersArray[0].cost[supplierIndex] +
                              item.allSuppliersArray[0].estimatedShipping[
                                supplierIndex
                              ],
                          )
                        : item.allSuppliersArray[0].estimatedShipping[
                            supplierIndex
                          ] > 0 &&
                          parseFloat(item.allSuppliersArray[0].weight) > 5
                        ? formatCentsToDollars(
                            item.quantity *
                              (item.allSuppliersArray[0].cost[supplierIndex] +
                                item.allSuppliersArray[0].estimatedShipping[
                                  supplierIndex
                                ]),
                          )
                        : item.allSuppliersArray[0].shippingCost[
                            supplierIndex
                          ] > 0 &&
                          parseFloat(item.allSuppliersArray[0].weight) <= 5
                        ? formatCentsToDollars(
                            item.quantity *
                              item.allSuppliersArray[0].cost[supplierIndex] +
                              item.allSuppliersArray[0].shippingCost[
                                supplierIndex
                              ],
                          )
                        : item.allSuppliersArray[0].shippingCost[
                            supplierIndex
                          ] > 0 &&
                          parseFloat(item.allSuppliersArray[0].weight) > 5
                        ? formatCentsToDollars(
                            item.quantity *
                              (item.allSuppliersArray[0].cost[supplierIndex] +
                                item.allSuppliersArray[0].shippingCost[
                                  supplierIndex
                                ]),
                          )
                        : '$0.00'}
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
              <TableRow>
                <TableCell className={classes.leadColumn}>
                  <SupplierOrderButton
                    row={row}
                    supplier={supplier}
                    isLoading={isLoading}
                    supplierRadioSelection={supplierRadioSelection}
                    onRadioChange={onRadioChange}
                    available={
                      supplierTotalObj.supplier === AD &&
                      supplierTotalObj.available
                        ? true
                        : available
                    }
                  />
                  {supplier === AD ? (
                    <Grid>
                      <Chip
                        style={{
                          color: calculatedResponse
                            ? getColor('purple', 'main')
                            : '#ffffff',
                          width: 'fit-content',
                          height: '100%',
                        }}
                        label={calculatedResponse ? 'Calculated' : 'Awaiting'}
                      />
                    </Grid>
                  ) : null}
                </TableCell>
                <TableCell>
                  <Grid
                    className={
                      totalCost <= Math.min(...totalsArray)
                        ? classes.lowestPrice
                        : classes.notLowestPrice
                    }
                  >
                    <Grid>
                      {supplier === WPS
                        ? 'WPS'
                        : supplier === AD
                        ? 'AD'
                        : supplier === RMATV
                        ? 'RMATV'
                        : supplier === DZONE
                        ? 'DZ'
                        : supplier}
                    </Grid>
                    <Grid>
                      <b>Total: </b>
                      {formatCentsToDollars(totalCost)}
                    </Grid>
                  </Grid>
                </TableCell>
                <TableCell>
                  <Grid className={classes.empty}></Grid>
                </TableCell>
              </TableRow>
              <TableRow></TableRow>
            </React.Fragment>
          );
        },
      );
    }
  } else {
    if (orderItems.length === 1) {
      const { allSuppliersArray } = orderItems[0];
      return (
        <TableRow key={orderItems[0].id}>
          <TableCell className={classes.leadColumn}>
            {orderItems[0].variationImages?.[0] ? (
              <img
                className={classes.image}
                src={orderItems[0].variationImages?.[0].imageUrl}
              />
            ) : (
              <Grid>
                <img
                  className={classes.image}
                  src={`https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`}
                  alt="placeholder image for product image"
                />
              </Grid>
            )}
            <Grid></Grid>
          </TableCell>
          <TableCell>
            <Grid>
              <b>Supplier: </b>
              {orderItems[0].supplierId === supplierIds[WPS]
                ? 'WPS'
                : orderItems[0].supplierId === supplierIds[AD]
                ? 'AD'
                : orderItems[0].supplierId === supplierIds[RMATV]
                ? 'RMATV'
                : orderItems[0].supplierId === supplierIds[DZONE]
                ? 'DZONE'
                : orderItems[0].supplierId === supplierIds[MOTO]
                ? 'MU'
                : `${supplierIds[orderItems[0].supplierId]}`}
            </Grid>
            <Grid>
              <b>SKU: </b>
              {orderItems[0].sku}
            </Grid>
            <Grid>
              <b>Qty: </b>
              {orderItems[0].quantity}
            </Grid>
          </TableCell>
          <TableCell>
            <Grid>
              <b>Cost: </b>
              {orderItems[0].supplierSubTotal > 0
                ? formatCentsToDollars(orderItems[0].supplierSubTotal)
                : allSuppliersArray[0].name.map((e, i) => {
                    if (e === orderItems[0].supplierName) {
                      return formatCentsToDollars(allSuppliersArray[0].cost[i]);
                    }
                  })}
            </Grid>
            <Grid>
              <b>Ship: </b>
              {row.supplierShippingCost > 0
                ? formatCentsToDollars(row.supplierShippingCost)
                : orderItems[0].supplierShippingCost > 0
                ? formatCentsToDollars(orderItems[0].supplierShippingCost)
                : allSuppliersArray[0].name.map((e, i) => {
                    if (e === orderItems[0].supplierName) {
                      if (allSuppliersArray[0].estimatedShipping[i] > 0) {
                        return formatCentsToDollars(
                          allSuppliersArray[0].estimatedShipping[i],
                        );
                      } else {
                        return formatCentsToDollars(
                          allSuppliersArray[0].shippingCost[i],
                        );
                      }
                    }
                  })}
            </Grid>
            <Grid>
              <b>Total: </b>
              {row.supplierShippingCost > 0 &&
              orderItems[0].supplierSubTotal > 0
                ? formatCentsToDollars(
                    row.supplierShippingCost + orderItems[0].supplierSubTotal,
                  )
                : orderItems[0].supplierShippingCost > 0 &&
                  orderItems[0].supplierSubTotal > 0
                ? formatCentsToDollars(
                    orderItems[0].supplierShippingCost +
                      orderItems[0].supplierSubTotal,
                  )
                : orderItems[0].allSuppliersArray[0].name.map((e, i) => {
                    if (e === orderItems[0].supplierName) {
                      if (
                        orderItems[0].allSuppliersArray[0].estimatedShipping[
                          i
                        ] > 0 &&
                        orderItems[0].allSuppliersArray[0].cost[i] > 0
                      ) {
                        return formatCentsToDollars(
                          orderItems[0].allSuppliersArray[0].estimatedShipping[
                            i
                          ] + orderItems[0].allSuppliersArray[0].cost[i],
                        );
                      } else if (
                        orderItems[0].allSuppliersArray[0].shippingCost[i] >
                          0 &&
                        orderItems[0].allSuppliersArray[0].cost[i] > 0
                      ) {
                        return formatCentsToDollars(
                          orderItems[0].allSuppliersArray[0].shippingCost[i] +
                            orderItems[0].allSuppliersArray[0].cost[i],
                        );
                      }
                    } ;
                  })}
            </Grid>
          </TableCell>
        </TableRow>
      );
    } else {
      const itemsCost = orderItems.every((item) => item.supplierSubTotal > 0)
        ? orderItems.reduce((total, item) => total + item.supplierSubTotal, 0)
        : orderItems.reduce((total, item) => {
            const supplierCost = item.allSuppliersArray[0].name.reduce(
              (sum, e, i) => {
                return e === orderItems[0].supplierName
                  ? sum + item.allSuppliersArray[0].cost[i] * item.quantity
                  : sum;
              },
              0,
            );
            return total + supplierCost;
          }, 0);
      const shippingCost =
        row.supplierShippingCost > 0
          ? row.supplierShippingCost
          : orderItems.every((item) => item.supplierShippingCost > 0)
          ? orderItems.reduce(
              (total, item) => total + item.supplierShippingCost,
              0,
            )
          : orderItems.every(
              (item, index) =>
                item.allSuppliersArray[0].estimatedShipping[index] > 0,
            )
          ? orderItems.reduce((total, item) => {
            console.log(item);
              const estimatedShippingCost =
                item.allSuppliersArray[0].name.reduce((sum, name, i) => {
                  return name === orderItems[0].supplierName
                    ? sum + item.allSuppliersArray[0].estimatedShipping[i]
                    : sum;
                }, 0);
              return total + estimatedShippingCost;
            }, 0)
          : orderItems.every(
              (item, index) =>
                item.allSuppliersArray[0].shippingCost[index] > 0,
            )
          ? orderItems.reduce((total, item) => {
              const shippingCost = item.allSuppliersArray[0].name.reduce(
                (sum, name, i) => {
                  return name === orderItems[0].supplierName
                    ? sum + item.allSuppliersArray[0].shippingCost[i]
                    : sum;
                },
                0,
              );
              return total + shippingCost;
            }, 0)
          : 0;
      const totalCost = itemsCost + shippingCost;
      return (
        <React.Fragment key={row.id}>
          {orderItems.map((item) => (
            <TableRow key={item.id}>
              <TableCell className={classes.leadColumn}>
                {item.variationImages?.[0] ? (
                  <Grid>
                    <img
                      className={classes.image}
                      src={item.variationImages?.[0].imageUrl}
                    />
                  </Grid>
                ) : (
                  <Grid>
                    <img
                      className={classes.image}
                      src={`https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`}
                      alt="placeholder image for product image"
                    />
                  </Grid>
                )}
                <Grid></Grid>
              </TableCell>
              <TableCell>
                <b>Supplier: </b>
                {item.supplierId === supplierIds[WPS]
                  ? 'WPS'
                  : item.supplierId === supplierIds[AD]
                  ? 'AD'
                  : item.supplierId === supplierIds[RMATV]
                  ? 'RMATV'
                  : item.supplierId === supplierIds[DZONE]
                  ? 'DZONE'
                  : item.supplierId === supplierIds[MOTO]
                  ? 'MU'
                  : `${supplierIds[item.supplierId]}`}
                <Grid>
                  <b>SKU: </b>
                  {item.sku}
                </Grid>
                <Grid>
                  <b>Qty: </b>
                  {item.quantity}
                </Grid>
              </TableCell>
              <TableCell>
                <Grid>
                  <b>Cost: </b>
                  {item.supplierSubTotal > 0
                    ? formatCentsToDollars(item.supplierSubTotal)
                    : item.allSuppliersArray[0].name.map((e, i) => {
                        if (e === item.supplierName) {
                          return formatCentsToDollars(
                            item.allSuppliersArray[0].cost[i],
                          );
                        }
                      })}
                </Grid>
                <Grid>
                  <b>Ship: </b>
                  {item.supplierShippingCost > 0
                    ? formatCentsToDollars(item.supplierShippingCost)
                    : item.allSuppliersArray[0].name.map((e, i) => {
                        if (e === item.supplierName) {
                          if (
                            item.allSuppliersArray[0].estimatedShipping[i] > 0
                          ) {
                            return formatCentsToDollars(
                              item.allSuppliersArray[0].estimatedShipping[i],
                            );
                          } else if (
                            item.allSuppliersArray[0].shippingCost[i] > 0
                          )
                            return formatCentsToDollars(
                              item.allSuppliersArray[0].shippingCost[i],
                            );
                        }
                      })}
                </Grid>
                <Grid>
                  {/* <b>Total: </b>
                  {item.supplierSubTotal > 0 && item.supplierShippingCost > 0
                    ? formatCentsToDollars(
                        item.quantity * item.supplierSubTotal +
                          item.supplierShippingCost,
                      )
                    : item.supplierSubTotal > 0 &&
                      item.allSuppliersArray[0].name.map((e, i) => {
                        if (e === item.supplierName) {
                          if (
                            item.allSuppliersArray[0].estimatedShipping[i] > 0
                          ) {
                            return formatCentsToDollars(
                              item.quantity * item.supplierSubTotal +
                                item.allSuppliersArray[0].estimatedShipping[i],
                            );
                          } else if (
                            item.allSuppliersArray[0].shippingCost[i] > 0
                          ) {
                            return formatCentsToDollars(
                              item.quantity * item.supplierSubTotal +
                                item.allSuppliersArray[0].shippingCost[i],
                            );
                          }
                        }
                      })
                    ? item.supplierShippingCost > 0 &&
                      item.allSuppliersArray[0].name.map((e, i) => {
                        if (e === item.supplierName) {
                          if (item.allSuppliersArray[0].cost[i] > 0) {
                            return formatCentsToDollars(
                              item.quantity * item.supplierShippingCost +
                                item.allSuppliersArray[0].cost[i],
                            );
                          }
                        }
                      })
                    : item.allSuppliersArray[0].name.map((e, i) => {
                        if (e === item.supplierName) {
                          if (
                            item.allSuppliersArray[0].estimatedShipping[i] >
                              0 &&
                            item.allSuppliersArray[0].cost[i] > 0
                          ) {
                            return formatCentsToDollars(
                              item.quantity *
                                item.allSuppliersArray[0].cost[i] +
                                item.allSuppliersArray[0].estimatedShipping[i],
                            );
                          } else if (
                            item.allSuppliersArray[0].shippingCost[i] > 0 &&
                            item.allSuppliersArray[0].cost[i] > 0
                          ) {
                            return formatCentsToDollars(
                              item.quantity *
                                item.allSuppliersArray[0].cost[i] +
                                item.allSuppliersArray[0].shippingCost[i],
                            );
                          }
                        } 
                      })} */}
                </Grid>
              </TableCell>
            </TableRow>
          ))}

          <TableRow>
            <TableCell className={classes.leadColumn}>
              <Grid className={classes.empty}></Grid>
            </TableCell>
            <TableCell>
              <Grid>
                <b>Total: </b>
                {itemsCost > 0 && shippingCost > 0
                  ? formatCentsToDollars(totalCost)
                  : 'N/A'}
              </Grid>
            </TableCell>
            <TableCell>
              <Grid></Grid>
            </TableCell>
          </TableRow>
        </React.Fragment>
      );
    }
  }
}
export default function OrderDrawerItemsTable({
  row,
  signatureChecked,
  handleSignatureRequiredCheckboxChange,
  isLoading,
  supplierRadioSelection,
  onRadioChange,
  totals,
  calculatedResponse,
}) {
  return (
    <>
      {(row.store === 'dzone' || row.store === 'ads') &&
      (row.orderStatus === 'UNASSIGNED' || row.orderStatus === 'ISSUE') &&
      row.orderItems &&
      row.orderItems[0]?.allSuppliersArray.length >= 1 ? (
        <FormControlLabel
          control={
            <Checkbox
              title="Signature Required"
              checked={signatureChecked}
              onChange={handleSignatureRequiredCheckboxChange}
            />
          }
          label="Signature Required"
        />
      ) : null}
      <TableContainer style={{ marginBottom: '10px' }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Items</TableCell>
              <TableCell>Supplier Cost</TableCell>
            </TableRow>
          </TableHead>
          <OrderItemsRows
            row={row}
            isLoading={isLoading}
            onRadioChange={onRadioChange}
            supplierRadioSelection={supplierRadioSelection}
            totals={totals}
            calculatedResponse={calculatedResponse}
          />
        </Table>
      </TableContainer>
    </>
  );
}
