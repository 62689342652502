import { atom } from 'recoil';

import type { AnalyticsOptionsAtom } from './types';

export const analyticsOptionsAtom = atom<AnalyticsOptionsAtom>({
  key: 'analytics-options',
  default: {
    newerThan: 13,
  },
});
