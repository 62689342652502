import dayjs from 'dayjs';
import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { getColor } from '@src/utils/colors';
import numberUtils from '@oneAppCore/utils/numbers';
import ApproveButton from './ApproveButton';
import Link from '@mui/material/Link';

import {
  Checkbox,
  Grid,
  InputAdornment,
  Typography,
  ListItem,
  Tooltip,
  IconButton,
  Button,
  Chip,
  Modal,
  Popper,
  Card,
  CardContent,
  FormControl,
  OutlinedInput,
} from '@mui/material';
import type {
  Column,
  ExportOption,
} from '@src/Components/common/containers/SearchView/types';
import FontAwesome from '@src/Components/common/FontAwesome';
import { dateFormat } from '@oneAppCore/constants/dates';
import { BLOB_STORAGE_NAME } from '@oneAppCore/constants/products';
import { DRAFT, REJECTED } from '@oneAppCore/constants/listings';
import VisibilityTwoToneIcon from '@mui/icons-material/VisibilityTwoTone';
import {
  AMAZON,
  AMAZON_ASIN_LINK,
  WALMART,
  WALMART_PRODUCT_LINK,
  EBAY,
  EBAY_PRODUCT_LINK,
  channelIds,
} from '@oneAppCore/constants/channels';
import DescriptionModal from './DescriptionModal/DescriptionModal';

export const columns = (
  changePriceFunc,
  handleMouseEnter,
  handleMouseLeave,
  tableRowsState,
  anchorEl,
  hoverOpen,
  popperStates,
): Column[] => [
  {
    title: 'Image',
    keyName: 'thumbnail',
    sortBy: false,
    align: 'left',
    customComponent: function ImageRender(row) {
      return (
        <>
          {row.listingImages?.[0] ? (
            <img src={row.listingImages[0]} alt="" width={50} height={50} />
          ) : (
            <img
              height={50}
              width={50}
              src={`https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`}
              alt="Image Placeholder"
            />
          )}
        </>
      );
    },
  },
  {
    title: 'Listing',
    keyName: 'listingName',
    sortBy: false,
    align: 'left',
    customComponent: function NameRender(row) {
      return (
        <Grid
          container
          style={{
            flexDirection: 'column',
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 400,
            fontSize: '12px',
            lineHeight: '18px',
            minWidth: '150px',
            maxWidth: '200px',
          }}
        >
          <Grid item style={{ maxWidth: 150 }}>
            {row.name}
            {row.variation ? ` - (${row.variation})` : ''}
          </Grid>
          <Grid item>
            <span style={{ fontWeight: 'bold', marginRight: '3px' }}>
              Channel ID:
            </span>
            {row?.channelProductId}
          </Grid>
          <Grid item>
            <span style={{ fontWeight: 'bold', marginRight: '3px' }}>SKU:</span>
            {row.listingSku}
          </Grid>
        </Grid>
      );
    },
  },
  {
    title: 'Created At',
    keyName: 'createdAt',
    sortBy: true,
    align: 'center',
    customComponent: function DateRender(row) {
      return (
        <p style={{ margin: 0 }}>{dayjs(row.createdAt).format(dateFormat)}</p>
      );
    },
  },
  {
    title: 'Channel',
    keyName: 'channelName',
    align: 'center',
    customComponent: function Channel(row) {
      let href = '';
      if (row?.channelProductId) {
        if (row?.channelId === channelIds[AMAZON]) {
          href = AMAZON_ASIN_LINK(row?.channelProductId);
        } else if (row?.channelId === channelIds[WALMART]) {
          href = WALMART_PRODUCT_LINK(row?.channelProductId);
        } else if (row?.channelId === channelIds[EBAY]) {
          href = EBAY_PRODUCT_LINK(row?.channelProductId);
        }
      }
      return (
        <Grid
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 5,
          }}
        >
          <img
            width={35}
            height={35}
            style={{ borderRadius: 10 }}
            src={
              row.icon != null
                ? row.icon
                : `https://${BLOB_STORAGE_NAME}.blob.core.windows.net/oneapp-images/imagePlaceholder.png`
            }
          />
          {href !== '' && (
            <Link href={href} target="_blank">
              <FontAwesome name="external-link-alt" type="fa" form="fa" />
            </Link>
          )}
        </Grid>
      );
    },
  },
  {
    title: 'Price',
    keyName: 'price',
    sortBy: true,
      align: 'center',
      customComponent: function price(row, index, updateFunction) {
        return (
          <Grid item>
            <FormControl style={{ margin: 1, width: 100 }}>
              <OutlinedInput
                id="standard-adornment-password"
                type="number"
                value={row.price}
                error={!row.price || row.price < 0}
                onChange={(e) => {
                  let value = e.currentTarget.value;
                  updateFunction({ ...row, price: parseFloat(value) }, index)
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <div style={{ color: !row.price || row.price < 0 ? 'red' : 'black' }}>$</div>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        );
      },
    },
    {
      title: 'Min Price',
      keyName: 'minPrice',
      sortBy: true,
      align: 'center',
      customComponent: function minPrice(row, index, updateFunction) {
        return (
          <Grid item>
            <FormControl style={{ margin: 1, width: 100 }}>
              <OutlinedInput
                id="standard-adornment-password"
                type="number"
                error={!row.minPrice || row.minPrice < 0}
                value={row.minPrice}
                onChange={(e) => {
                  let value = e.currentTarget.value;
                  updateFunction({ ...row, minPrice: parseFloat(value) }, index)
                }}
                startAdornment={
                  <InputAdornment position="start">
                    <div style={{ color: !row.minPrice || row.minPrice < 0 ? 'red' : 'black' }}>$</div>
                  </InputAdornment>
                }
              />
            </FormControl>
          </Grid>
        );
      },
    },
    {
      title: 'Max Price',
      keyName: 'maxPrice',
      sortBy: true,
      align: 'center',
      customComponent: function maxPrice(row, index, updateFunction) {
      return (
        <Grid item>
          <FormControl style={{ margin: 1, width: 100 }}>
            <OutlinedInput
              id="standard-adornment-password"
              type="number"
              value={row.maxPrice}
              error={!row.maxPrice || row.maxPrice < 0}
              onChange={(e) => {
                let value = e.currentTarget.value;
                updateFunction({ ...row, maxPrice: parseFloat(value) }, index)
              }}
              startAdornment={
                <InputAdornment position="start">
                  <div style={{ color: !row.maxPrice || row.maxPrice < 0 ? 'red' : 'black' }}>$</div>
                </InputAdornment>
              }
            />
          </FormControl>
        </Grid>
      );
    },
  },
  {
    title: 'Cost',
    keyName: 'cost',
    sortBy: true,
    align: 'center',
    customComponent: function cost(row) {
      // const decreased = row.itemCost < 700;
      let totalCost = row?.cost;
      if (row?.quantity) {
        totalCost = totalCost * row?.quantity;
      };
      const cost = totalCost ? totalCost / 100 : 0;
      return (
        <Grid item>
          <Typography style={{ maxWidth: '120px' }}>
            $ {parseFloat(`${cost}`).toFixed(2)}
            </Typography>
          </Grid>
        );
      },
    },
    {
      title: 'Profit',
      keyName: 'profit',
      sortBy: false,
      align: 'left',
      customComponent: function cost(row) {
        const price = row.price ? (row.price * 100) * 0.88 : 0;
        const cost = row.cost ? row.quantity ? row.cost * row.quantity : row.cost : 0;
        const profit = price > 0 ? (price - cost) / 100 : 0;
        return (
          <Grid item>
            <Typography style={{ maxWidth: '120px', color: profit > 0 ? 'green' : 'red' }}>
              $ {parseFloat(`${Math.abs(profit)}`).toFixed(2)}
          </Typography>
        </Grid>
      );
    },
  },
  {
    title: 'Category',
    keyName: 'categories',
    sortBy: true,
    align: 'center',
  },
  {
    title: 'Description',
    keyName: 'description',
    align: 'left',
    sortBy: true,
    customComponent: function Description(row) {
      return (
        <Grid
          item
          container
          style={{ justifyContent: 'center' }}
        >
          <DescriptionModal row={row} />
        </Grid>
      );
    },
  },
    // {
    //   title: 'SEO Score',
    //   keyName: 'seoScore',
    //   sortBy: false,
    //   align: 'center',
    //   customComponent: function seoScore(row) {
    //     let productScore = 0;
    //     if (row.name) {
    //       productScore += row.name.length;
    //     }
    //     if (row.manufacturerNo) {
    //       productScore += 2;
    //     }
    //     if (row.listingSku) {
    //       productScore += 1;
    //     }
    //     if (row.listingImages) {
    //       productScore += 10;
    //     }
    //     const color =
    //       productScore < 30
    //         ? 'secondary'
    //         : productScore >= 30 && productScore <= 60
    //         ? 'primary'
    //         : 'default';

    //     return <Chip color={color} size="small" label={productScore} />;
    //   },
    // },
  {
    title: 'Status',
    keyName: 'status',
    sortBy: false,
    align: 'center',
    customComponent: function StatusRender(row) {
      const rowId = row.id;
      return (
        <Grid item>
          <p
            style={{
              background: row.status === DRAFT ? '#FF9F43' : 'grey',
              fontFamily: 'Montserrat, sans-serif',
              fontWeight: 400,
              fontSize: '14px',
              lineHeight: '18px',
              padding: '1px 9px',
              color: 'white',
              borderRadius: '17px',
              textAlign: 'center',
            }}
            onMouseEnter={(e) => {
              handleMouseEnter(e, rowId);
            }}
            onMouseLeave={() => handleMouseLeave(rowId)}
          >
            {row?.status?.charAt(0).toUpperCase() + row?.status?.slice(1)}
          </p>
          {row?.message && <Popper
            style={{ border: '#000000 1px solid', borderRadius: 5 }}
            anchorEl={anchorEl}
            transition
            open={popperStates[rowId] || false}
          >
            <Card>
              <CardContent>
                <Grid alignItems="center" justifyContent="center" container>
                  <Typography>{row?.message}</Typography>
                </Grid>
              </CardContent>
            </Card>
          </Popper>}
        </Grid>
      );
    },
  },
  {
    title: 'Created By',
    keyName: 'createdByName',
    sortBy: false,
    align: 'center',
  },
  {
    title: 'Actions',
    keyName: 'edit',
    sortBy: false,
    align: 'center',
    customComponent: function EditRender(row) {
      const history = useHistory();
      const { storeProductId, status } = row;
      const id = row.storeProductId;
      return (
        <Grid
          container
          direction="row"
          alignItems="center"
          justifyContent="center"
          style={{
            minWidth: '150px',
          }}
        >
          <Grid item xs={3}>
            <Tooltip title={'View Product'}>
              <IconButton onClick={() => history.push(`/products/view/${id}`)}>
                {/* <FontAwesome
                    name="eye"
                    type="fal"
                    form="fa"
                    onClick={() => history.push(`/products/view/${storeProductId}`)}
                    style={{ cursor: 'pointer', color: '#0d6efd' }}
                  /> */}
                <VisibilityTwoToneIcon color="primary" />
              </IconButton>
            </Tooltip>
          </Grid>
          {status == DRAFT ? (
            <Grid item container xs={6}>
              <Grid item xs={6}>
                <ApproveButton row={row} />
              </Grid>
              <Grid item xs={6}>
                <ApproveButton modalType={REJECTED} row={row} />
              </Grid>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      );
    },
  },
];
